<template>
  <v-container :fluid="$vuetify.breakpoint.lgAndDown">
    <v-row>
      <v-col
        ><supplier-form
          :supplier="supplier"
          :submit="createSupplier"
          :loading="loading"
      /></v-col>
    </v-row>
    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" top right>
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.visible = false">
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import SupplierForm from "@/components/SupplierForm";
import axios from "axios";

export default {
  components: { SupplierForm },
  data: () => ({
    loading: false,
    supplier: {
      name: "",
      contact: "",
      addressOne: "",
      addressTwo: "",
      city: "",
      zip: "",
      country: "",
      email: "",
      phone: "",
    },
    snackbar: {
      visible: false,
      color: "",
      text: "",
    },
  }),
  methods: {
    async createSupplier() {
      this.loading = true;
      try {
        const { data: supplier } = await axios({
          method: "post",
          url: `${process.env.VUE_APP_API_URI}/suppliers`,
          data: {
            ...this.supplier,
          },
        });
        localStorage.setItem("supplier", JSON.stringify(supplier));
        localStorage.setItem(
          "snackbar",
          JSON.stringify({
            visible: true,
            color: "success",
            text: "Fournisseur créé",
          })
        );
        if (
          this.$route.query &&
          this.$route.query.from &&
          this.$route.query.from === "po"
        ) {
          this.$router.go(-1);
        } else {
          this.$router.push(`/suppliers/${supplier._id}`);
        }
      } catch (error) {
        this.snackbar = {
          visible: true,
          color: "error",
          text: "Erreur de création du fournisseur",
        };
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
