<template>
  <v-container :fluid="$vuetify.breakpoint.lgAndDown">
    <v-row>
      <v-col
        cols="12"
        md="4"
        v-if="['tablet', 'mobile'].indexOf(deviceType) !== -1"
      >
        <div style="position: sticky; top: 76px">
          <v-sheet rounded="lg" min-height="268">
            <div class="title text-center mt-3">Validations</div>
            <div class="text-center mb-3">
              {{
                this.validations &&
                this.validations.length &&
                this.validations[this.validations.length - 1].order &&
                this.validations[this.validations.length - 1].order.buyer
                  ? `Envoi commande : ${
                      this.validations[this.validations.length - 1].order.buyer
                    }`
                  : ""
              }}
            </div>
            <validation-path
              :validations="validations"
              :updateValidation="updateValidation"
              :rejectValidation="rejectValidation"
              :returnAccounting="returnAccounting"
              :deletePo="deletePo"
              :currentValidation="currentValidation"
              :selectBinder="selectBinder"
              :activeIndex="activeIndex"
              :validationSubmitted="validationSubmitted"
              :downloadFile="downloadFile"
              :downloadArchive="downloadArchive"
              :updateNoticeStatus="updateNoticeStatus"
              :submitToAccounting="submitToAccounting"
              :orderBuyer="orderBuyer"
              :orderOther="orderOther"
              :orderSupplier="orderSupplier"
              :loading="loading"
              :updatable="updatable"
              :cancelValidation="cancelValidation"
              :validationUpdated="validationUpdated"
              :acknowledgeCancelation="acknowledgeCancelation"
              :setOrdered="setOrdered"
              :isAccountant="isAccountant"
              :deletePoForAccountant="deletePoForAccountant"
            />
          </v-sheet>
        </div>
      </v-col>
      <v-col cols="12" md="8"
        ><pdf-viewer
          v-if="this.validations.length && deviceType === 'desktop'"
          :filename="filename"
        />
        <pdf-viewer-mobile
          v-if="
            this.validations.length &&
            ['tablet', 'mobile'].indexOf(deviceType) !== -1
          "
          :filename="filename"
        />
      </v-col>
      <v-col cols="12" md="4" v-if="deviceType === 'desktop'">
        <div style="position: sticky; top: 76px">
          <v-sheet rounded="lg" min-height="268">
            <div class="title text-center mt-3">Validations</div>
            <div class="text-center mb-3">
              {{
                this.validations &&
                this.validations.length &&
                this.validations[this.validations.length - 1].order &&
                this.validations[this.validations.length - 1].order.buyer
                  ? `Envoi commande : ${
                      this.validations[this.validations.length - 1].order.buyer
                    }`
                  : ""
              }}
            </div>
            <validation-path
              :validations="validations"
              :updateValidation="updateValidation"
              :rejectValidation="rejectValidation"
              :returnAccounting="returnAccounting"
              :redirectValidation="redirectValidation"
              :deletePo="deletePo"
              :currentValidation="currentValidation"
              :selectBinder="selectBinder"
              :activeIndex="activeIndex"
              :validationSubmitted="validationSubmitted"
              :downloadFile="downloadFile"
              :downloadArchive="downloadArchive"
              :updateNoticeStatus="updateNoticeStatus"
              :submitToAccounting="submitToAccounting"
              :orderBuyer="orderBuyer"
              :orderOther="orderOther"
              :orderSupplier="orderSupplier"
              :loading="loading"
              :updatable="updatable"
              :cancelValidation="cancelValidation"
              :validationUpdated="validationUpdated"
              :acknowledgeCancelation="acknowledgeCancelation"
              :setOrdered="setOrdered"
              :isAccountant="isAccountant"
              :deletePoForAccountant="deletePoForAccountant"
            />
          </v-sheet>
        </div>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" top right>
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.visible = false">
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import PdfViewer from "@/components/PdfViewer";
import PdfViewerMobile from "@/components/PdfViewerMobile";
import ValidationPath from "@/components/ValidationPath";
import axios from "axios";
import { format, parseISO } from "date-fns";
import FileDownload from "js-file-download";

export default {
  components: { PdfViewer, PdfViewerMobile, ValidationPath },
  async mounted() {
    try {
      const {
        data: { validations, binder, createdBy, updatedAt, updatable },
      } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/orders/${this.$route.params._id}/validations`,
      });
      this.validations = validations;
      this.binder = binder;
      this.createdBy = createdBy;
      this.updatedAt = updatedAt;
      this.updatable = updatable;
      this.currentValidation = this.validations.find(
        (v) =>
          [
            "pending",
            "to-order-buyer-pending",
            "to-order-contractor-pending",
          ].indexOf(v.status) !== -1 && v.type === "validation"
      );
      this.filename = this.validations[this.validations.length - 1].order
        .validatedBinder
        ? this.validations[this.validations.length - 1].order.validatedBinder
        : this.validations[this.validations.length - 1].order.binder;
      this.activeIndex =
        this.validations.map((v) => v.status).indexOf("pending") > -1
          ? this.validations.length
          : 0;
    } catch (error) {
      this.openSnackbar("Erreur de chargement", "error");
      console.log(error);
    }
  },
  props: ["updateBadge", "isAccountant"],
  data: () => ({
    loading: false,
    snackbar: {
      visible: false,
      color: "",
      text: "",
    },
    activeIndex: 0,
    validations: [],
    binder: "",
    createdBy: {},
    updatedAt: null,
    currentValidation: {},
    filename: "",
    format,
    parseISO,
    validationSubmitted: false,
    updatable: false,
    validationUpdated: false,
  }),
  methods: {
    setActiveIndex(index) {
      this.activeIndex = index;
    },
    selectBinder(binder, index) {
      this.filename = binder;
      this.setActiveIndex(index);
    },
    async updateNoticeStatus(validationId, notice, status, validationComment) {
      this.loading = true;
      try {
        const {
          data: { validation, previous },
        } = await axios({
          method: "post",
          url: `${process.env.VUE_APP_API_URI}/validations/${validationId}`,
          data: { status, notice, validationComment },
        });
        this.validations.push(validation);

        if (previous) {
          this.validations.find((v) => v._id === previous._id).position =
            previous.position;
        }
        this.validations = this.validations.sort(
          (a, b) => a.position - b.position
        );
        this.updateBadge("pendingNotices", -1);
      } catch (error) {
        this.openSnackbar("Erreur de mise à jour", "error");
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async acknowledgeCancelation(_id) {
      this.loading = true;
      try {
        await axios({
          method: "patch",
          url: `${process.env.VUE_APP_API_URI}/validations/${_id}?type=acknowledge`,
        });
        this.validations.find((v) => v._id === _id).status = "cancel";

        this.validations = this.validations.sort(
          (a, b) => a.position - b.position
        );
        this.validationUpdated = true;
        this.updateBadge("pendingValidations", -1);
      } catch (error) {
        this.openSnackbar("Erreur de mise à jour", "error");
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async setOrdered(_id, status) {
      console.log(status);
      this.loading = true;
      try {
        const { data: updatedValidation } = await axios({
          method: "patch",
          url: `${process.env.VUE_APP_API_URI}/validations/${_id}?type=ordered`,
        });
        this.validations.find((v) => v._id === _id).status = "ordered";
        this.validations.find((v) => v._id === _id).orderedAt =
          updatedValidation.orderedAt;

        this.validations = this.validations.sort(
          (a, b) => a.position - b.position
        );
        this.validationUpdated = true;

        if (status === "to-order-buyer-pending") {
          this.updateBadge("toOrder", -1);
        } else {
          this.updateBadge("toOrderContractor", -1);
        }
      } catch (error) {
        this.openSnackbar("Erreur de mise à jour", "error");
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async cancelValidation(email, fullname) {
      try {
        const currentValidation = this.validations.find(
          (v) => v.status === "pending" && v.type === "validation"
        );
        const { data: nextValidation } = await axios({
          method: "patch",
          url: `${process.env.VUE_APP_API_URI}/validations/${currentValidation._id}?type=cancel`,
          data: {
            email,
            fullname,
            order: currentValidation.order,
          },
        });
        this.validations.find(
          (v) => v.status === "pending" && v.type === "validation"
        ).updatedAt = nextValidation.updatedAt;
        this.validations.find(
          (v) => v.type === "validation" && v.status === "pending"
        ).status = "cancel-pending";

        this.validations.push(nextValidation);
        this.validations = this.validations.sort(
          (a, b) => a.position - b.position
        );
        this.selectBinder(nextValidation.order.binder, this.validations.length);
        this.validationUpdated = true;
        this.updatable = true;
        if (nextValidation.email !== currentValidation.email) {
          if (currentValidation.level === 0) {
            this.updateBadge("pendingUpdates", -1);
          } else {
            this.updateBadge("pendingValidations", -1);
          }
        }

        if (currentValidation.level === 0) {
          this.updateBadge("pendingUpdates", -1);
          if (nextValidation.email === currentValidation.email) {
            this.updateBadge("pendingValidations", 1);
          }
        }
      } catch (error) {
        this.openSnackbar("Erreur de mise à jour", "error");
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async updateValidation(email, fullname, validationComment) {
      this.loading = true;
      try {
        const currentValidation = this.validations.find(
          (v) => v.status === "pending" && v.type === "validation"
        );
        const { data: nextValidations } = await axios({
          method: "patch",
          url: `${process.env.VUE_APP_API_URI}/validations/${currentValidation._id}?type=accept`,
          data: {
            email,
            fullname,
            order: currentValidation.order,
            validationComment,
          },
        });
        this.validations.find(
          (v) => v.type === "validation" && v.status === "pending"
        ).comment = validationComment;
        this.validations.find(
          (v) => v.status === "pending" && v.type === "validation"
        ).updatedAt = format(new Date(), "yyyy-MM-dd");
        this.validations.find(
          (v) => v.type === "validation" && v.status === "pending"
        ).status = "validated";

        nextValidations.map((nv) => this.validations.push(nv));
        this.validations = this.validations.sort(
          (a, b) => a.position - b.position
        );
        const nextValidation = nextValidations.find(
          (nv) => nv.type === "validation"
        );
        this.selectBinder(nextValidation.order.binder, this.validations.length);
        this.validationSubmitted = true;
        this.updatable = true;
        if (nextValidation.email !== currentValidation.email) {
          if (currentValidation.level === 0) {
            this.updateBadge("pendingUpdates", -1);
          } else {
            this.updateBadge("pendingValidations", -1);
          }
        }
        if (currentValidation.level === 0) {
          this.updateBadge("pendingUpdates", -1);
          if (nextValidation.email === currentValidation.email) {
            this.updateBadge("pendingValidations", 1);
          }
        }
      } catch (error) {
        this.openSnackbar("Erreur de mise à jour", "error");
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async rejectValidation(validationComment) {
      this.loading = true;
      try {
        const currentValidation = this.validations.find(
          (v) =>
            ["pending", "to-order-contractor-pending"].indexOf(v.status) > -1 &&
            v.type === "validation"
        );
        const { data: nextValidations } = await axios({
          method: "patch",
          url: `${process.env.VUE_APP_API_URI}/validations/${currentValidation._id}?type=reject`,
          data: {
            order: currentValidation.order,
            validationComment,
          },
        });
        this.validations.find(
          (v) => v.type === "validation" && v.status === "pending"
        ).validatorTla = nextValidations[0].previousTla;
        this.validations.find(
          (v) =>
            ["pending", "to-order-contractor-pending"].indexOf(v.status) > -1 &&
            v.type === "validation"
        ).comment = validationComment;
        this.validations.find(
          (v) =>
            ["pending", "to-order-contractor-pending"].indexOf(v.status) > -1 &&
            v.type === "validation"
        ).updatedAt = format(new Date(), "yyyy-MM-dd");
        this.validations.find(
          (v) =>
            ["pending", "to-order-contractor-pending"].indexOf(v.status) > -1 &&
            v.type === "validation"
        ).status = "rejected";

        if (nextValidations.length) {
          nextValidations.map((nv) => this.validations.push(nv));
          this.validations = this.validations.sort(
            (a, b) => a.position - b.position
          );
          this.setActiveIndex(this.validations.length);
        } else {
          this.setActiveIndex(0);
        }
        this.updateBadge("pendingValidations", -1);
        this.validationSubmitted = true;
      } catch (error) {
        this.openSnackbar("Erreur de mise à jour", "error");
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async returnAccounting(validationComment) {
      this.loading = true;
      try {
        const currentValidation = this.validations.find(
          (v) =>
            ["to-order-contractor-pending", "to-order-buyer-pending"].indexOf(
              v.status
            ) > -1 && v.type === "validation"
        );
        const { data: nextValidations } = await axios({
          method: "patch",
          url: `${process.env.VUE_APP_API_URI}/validations/${currentValidation._id}?type=return&accounting=true`,
          data: {
            order: currentValidation.order,
            validationComment,
          },
        });
        this.validations.find(
          (v) =>
            v.type === "validation" &&
            ["to-order-contractor-pending", "to-order-buyer-pending"].indexOf(
              v.status
            ) > -1
        ).comment = validationComment;
        this.validations.find(
          (v) =>
            ["to-order-contractor-pending", "to-order-buyer-pending"].indexOf(
              v.status
            ) > -1 && v.type === "validation"
        ).updatedAt = format(new Date(), "yyyy-MM-dd");
        this.validations.find(
          (v) =>
            v.type === "validation" &&
            ["to-order-contractor-pending", "to-order-buyer-pending"].indexOf(
              v.status
            ) > -1
        ).status = "redirected";

        nextValidations.map((nv) => this.validations.push(nv));
        this.validations = this.validations.sort(
          (a, b) => a.position - b.position
        );
        const nextValidation = nextValidations.find(
          (nv) => nv.type === "validation"
        );
        this.selectBinder(nextValidation.order.binder, this.validations.length);
        this.validationSubmitted = true;
        this.updatable = true;
        this.updateBadge("toOrderContractor", -1);
      } catch (error) {
        this.openSnackbar("Erreur de mise à jour", "error");
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async redirectValidation(email, fullname, validationComment) {
      this.loading = true;
      try {
        const currentValidation = this.validations.find(
          (v) => v.status === "pending" && v.type === "validation"
        );
        const { data: nextValidations } = await axios({
          method: "patch",
          url: `${process.env.VUE_APP_API_URI}/validations/${currentValidation._id}?type=redirection`,
          data: {
            email,
            fullname,
            order: currentValidation.order,
            validationComment,
          },
        });
        this.validations.find(
          (v) => v.type === "validation" && v.status === "pending"
        ).comment = validationComment;
        this.validations.find(
          (v) => v.status === "pending" && v.type === "validation"
        ).updatedAt = format(new Date(), "yyyy-MM-dd");
        this.validations.find(
          (v) => v.type === "validation" && v.status === "pending"
        ).status = "redirected";

        nextValidations.map((nv) => this.validations.push(nv));
        this.validations = this.validations.sort(
          (a, b) => a.position - b.position
        );
        const nextValidation = nextValidations.find(
          (nv) => nv.type === "validation"
        );
        this.selectBinder(nextValidation.order.binder, this.validations.length);
        this.validationSubmitted = true;
        this.updatable = true;
        if (nextValidation.email !== currentValidation.email) {
          if (currentValidation.level === 0) {
            this.updateBadge("pendingUpdates", -1);
          } else {
            this.updateBadge("pendingValidations", -1);
          }
        }
        if (currentValidation.level === 0) {
          this.updateBadge("pendingUpdates", -1);
          if (nextValidation.email === currentValidation.email) {
            this.updateBadge("pendingValidations", 1);
          }
        }
      } catch (error) {
        this.openSnackbar("Erreur de mise à jour", "error");
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async deletePoForAccountant(validationComment) {
      this.loading = true;
      try {
        console.log(this.$route.params._id);
        console.log(validationComment);
        const { data: deletedValidation } = await axios({
          method: "delete",
          url: `${process.env.VUE_APP_API_URI}/orders/accountant/${this.$route.params._id}`,
          data: { validationComment },
        });
        this.validations.push(deletedValidation);
        this.selectBinder(
          deletedValidation.order.binder,
          this.validations.length
        );
        this.validationSubmitted = true;
      } catch (error) {
        this.openSnackbar("Erreur de mise à jour", "error");
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async deletePo(validationComment) {
      this.loading = true;
      try {
        const currentValidation = this.validations.find(
          (v) => v.status === "pending" && v.type === "validation"
        );
        const { data: deletedValidation } = await axios({
          method: "delete",
          url: `${process.env.VUE_APP_API_URI}/validations/${currentValidation._id}`,
          data: { validationComment },
        });
        this.validations.find(
          (v) => v.type === "validation" && v.status === "pending"
        ).validatorTla = deletedValidation.previousTla;
        this.validations.find((v) => v._id === deletedValidation._id).comment =
          validationComment;
        this.validations.find(
          (v) => v.status === "pending" && v.type === "validation"
        ).updatedAt = format(new Date(), "yyyy-MM-dd");
        this.validations.find((v) => v._id === deletedValidation._id).status =
          "deleted";
        this.currentValidation = {};
        this.validationSubmitted = true;
        this.setActiveIndex(0);
        this.updateBadge("pendingValidations", -1);
      } catch (error) {
        this.openSnackbar("Erreur de mise à jour", "error");
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async submitToAccounting(validationComment) {
      this.loading = true;
      try {
        const currentValidation = this.validations.find(
          (v) => v.status === "pending" && v.type === "validation"
        );
        const { data: nextValidations } = await axios({
          method: "patch",
          url: `${process.env.VUE_APP_API_URI}/validations/${currentValidation._id}?type=accept&accounting=true`,
          data: {
            order: currentValidation.order,
            validationComment,
          },
        });
        this.validations.find(
          (v) => v.type === "validation" && v.status === "pending"
        ).comment = validationComment;
        this.validations.find(
          (v) => v.status === "pending" && v.type === "validation"
        ).updatedAt = format(new Date(), "yyyy-MM-dd");
        this.validations.find(
          (v) => v.type === "validation" && v.status === "pending"
        ).status = "validated";
        nextValidations.map((nv) => this.validations.push(nv));
        this.validations = this.validations.sort(
          (a, b) => a.position - b.position
        );
        const nextValidation = nextValidations.find(
          (nv) => nv.type === "validation"
        );
        this.selectBinder(nextValidation.order.binder, this.validations.length);
        this.updateBadge("pendingValidations", -1);
        this.validationSubmitted = true;
      } catch (error) {
        this.openSnackbar("Erreur de mise à jour", "error");
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async orderBuyer(ccFinance, pil, filiere, validationComment) {
      this.loading = true;
      try {
        const currentValidation = this.validations.find(
          (v) => v.status === "pending" && v.type === "validation"
        );
        const { data: nextValidation } = await axios({
          method: "patch",
          url: `${process.env.VUE_APP_API_URI}/validations/${currentValidation._id}?type=order-buyer`,
          data: {
            order: currentValidation.order,
            ccFinance,
            pil,
            filiere,
            validationComment,
          },
        });
        this.validations.find(
          (v) => v.type === "validation" && v.status === "pending"
        ).validatorTla = nextValidation.previousTla;
        this.validations.find(
          (v) => v.type === "validation" && v.status === "pending"
        ).order.binder = nextValidation.order.binder;
        this.validations.find(
          (v) => v.type === "validation" && v.status === "pending"
        ).comment = validationComment;
        this.validations.find(
          (v) => v.status === "pending" && v.type === "validation"
        ).updatedAt = format(new Date(), "yyyy-MM-dd");
        this.validations.find(
          (v) => v.type === "validation" && v.status === "pending"
        ).status = "validated";
        this.validations.push(nextValidation);
        this.validations = this.validations.sort(
          (a, b) => a.position - b.position
        );
        this.selectBinder(nextValidation.order.binder, this.validations.length);
        this.updateBadge("pendingValidations", -1);
        this.validationSubmitted = true;
      } catch (error) {
        this.openSnackbar("Erreur de mise à jour", "error");
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async orderOther(contractor, ccFinance, pil, filiere, validationComment) {
      this.loading = true;
      try {
        const currentValidation = this.validations.find(
          (v) => v.status === "pending" && v.type === "validation"
        );
        const { data: nextValidation } = await axios({
          method: "patch",
          url: `${process.env.VUE_APP_API_URI}/validations/${currentValidation._id}?type=order-other`,
          data: {
            order: currentValidation.order,
            contractor,
            ccFinance,
            pil,
            filiere,
            validationComment,
          },
        });
        this.validations.find(
          (v) => v.type === "validation" && v.status === "pending"
        ).comment = validationComment;
        this.validations.find(
          (v) => v.type === "validation" && v.status === "pending"
        ).validatorTla = nextValidation.previousTla;
        this.validations.find(
          (v) => v.type === "validation" && v.status === "pending"
        ).order.binder = nextValidation.order.binder;
        this.validations.find(
          (v) => v.status === "pending" && v.type === "validation"
        ).updatedAt = format(new Date(), "yyyy-MM-dd");
        this.validations.find(
          (v) => v.type === "validation" && v.status === "pending"
        ).status = "validated";
        this.validations.push(nextValidation);
        this.validations = this.validations.sort(
          (a, b) => a.position - b.position
        );
        this.selectBinder(nextValidation.order.binder, this.validations.length);
        this.updateBadge("pendingValidations", -1);
        this.validationSubmitted = true;
      } catch (error) {
        this.openSnackbar("Erreur de mise à jour", "error");
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async orderSupplier(ccFinance, pil, filiere, html, ccs) {
      this.loading = true;

      try {
        const currentValidation = this.validations.find(
          (v) => v.status === "pending" && v.type === "validation"
        );
        const { data: nextValidation } = await axios({
          method: "patch",
          url: `${process.env.VUE_APP_API_URI}/validations/${currentValidation._id}?type=order-supplier`,
          data: {
            order: currentValidation.order,
            ccFinance,
            pil,
            filiere,
            html,
            ccs,
          },
        });
        this.validations.find(
          (v) => v.type === "validation" && v.status === "pending"
        ).validatorTla = nextValidation.previousTla;
        this.validations.find(
          (v) => v.type === "validation" && v.status === "pending"
        ).order.binder = nextValidation.order.binder;
        this.validations.find(
          (v) => v.status === "pending" && v.type === "validation"
        ).updatedAt = format(new Date(), "yyyy-MM-dd");
        this.validations.find(
          (v) => v.type === "validation" && v.status === "pending"
        ).status = "validated";
        this.validations.push(nextValidation);
        this.validations = this.validations.sort(
          (a, b) => a.position - b.position
        );
        this.selectBinder(nextValidation.order.binder, this.validations.length);
        this.updateBadge("pendingValidations", -1);
        this.validationSubmitted = true;
      } catch (error) {
        this.openSnackbar("Erreur de mise à jour", "error");
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async downloadFile(filename, downloadname) {
      const { data } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/files/${filename}`,
        responseType: "arraybuffer",
      });
      FileDownload(data, downloadname);
    },
    async downloadArchive(validationId, downloadname) {
      const { data } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/validations/${validationId}/archive`,
        responseType: "arraybuffer",
      });
      FileDownload(data, downloadname);
    },
    openSnackbar(text, color) {
      this.snackbar = { text, color, visible: true };
    },
  },
  computed: {
    deviceType() {
      const ua = navigator.userAgent;
      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return "tablet";
      } else if (
        /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
          ua
        )
      ) {
        return "mobile";
      }
      return "desktop";
    },
  },
};
</script>

<style>
.fixed {
  position: fixed;
  width: 25%;
  overflow-y: scroll;
  max-height: 120%;
}
</style>