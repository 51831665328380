<template>
  <v-container :fluid="$vuetify.breakpoint.lgAndDown">
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <div>Validations</div>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Rechercher..."
              single-line
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :loading="loading"
            :items="validations"
            :server-items-length="total"
            :search="search"
            class="elevation-1"
            :options.sync="options"
            :footer-props="{
              itemsPerPageOptions: [10, 30, 50],
              itemsPerPageText: 'Élements par page',
            }"
            @pagination="paginationUpdate"
          >
            <!-- eslint-disable-next-line vue/valid-v-slot justification: possible according to vuetify documentation -->
            <template
              v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }"
              >{{ pageStart }}-{{ pageStop }} de {{ itemsLength }}</template
            >
            <template v-slot:body="{ items }">
              <tbody class="text-left cursor-pointer">
                <tr
                  v-for="item in items"
                  :key="item._id"
                  @click="$router.push(`/orders/${item._id}/validations`)"
                >
                  <td
                    :class="
                      item.deletedAt ? 'text-decoration-line-through' : ''
                    "
                  >
                    E-{{ item.orderId }}-{{ item.creatorTla }}
                  </td>
                  <td
                    :class="
                      item.deletedAt ? 'text-decoration-line-through' : ''
                    "
                  >
                    {{ item.supplier.name }}
                  </td>
                  <td
                    :class="
                      item.deletedAt ? 'text-decoration-line-through' : ''
                    "
                  >
                    {{
                      new Intl.NumberFormat("fr-CH", {
                        style: "currency",
                        currency: "CHF",
                      }).format(item.computedTotal)
                    }}
                  </td>
                  <td
                    :class="
                      item.deletedAt ? 'text-decoration-line-through' : ''
                    "
                  >
                    {{ item.year }}
                  </td>
                  <td
                    :class="
                      item.deletedAt ? 'text-decoration-line-through' : ''
                    "
                  >
                    {{ format(parseISO(item.poDate), "dd.MM.yyyy") }}
                  </td>
                </tr>
              </tbody></template
            >
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" top right>
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.visible = false">
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import { debounce } from "lodash";
import Intl from "intl";
import { format, parseISO } from "date-fns";

export default {
  async mounted() {
    await this.getDataFromApi();
  },
  props: ["setBadge"],
  data: () => ({
    snackbar: {
      visible: false,
      color: "",
      text: "",
    },
    validations: undefined,
    search: "",
    loading: false,
    deleteConfirmation: "",
    total: 0,
    deletedAt: false,
    options: {},
    headers: [
      {
        text: "N° BC",
        align: "start",
        value: "orderId",
      },
      { text: "Fournisseur", value: "supplier.name" },
      { text: "Montant", value: "computedTotal" },
      { text: "Année", value: "year" },
      { text: "Date de création", value: "poDate" },
    ],
    Intl,
    format,
    parseISO,
  }),
  methods: {
    paginationUpdate(value) {
      if (this.$route.query && this.$route.query.status) {
        if (this.$route.query.status === "processing") {
          if (this.$route.query.type === "notice") {
            this.setBadge("pendingNotices", value.itemsLength);
          } else {
            this.setBadge("pendingValidations", value.itemsLength);
          }
        } else {
          if (this.$route.query.status === "to-update") {
            this.setBadge("pendingUpdates", value.itemsLength);
          }
        }
      }
    },
    async getDataFromApi() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const { data } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/validations?status=${this.$route.query.status}&type=${this.$route.query.type}&search=${this.search}&page=${page}&sortBy=${sortBy}&sortDesc=${sortDesc}&itemsPerPage=${itemsPerPage}`,
      });
      this.loading = false;
      let { docs: items } = data;
      const total = data.totalDocs;
      this.total = total;
      this.validations = items;
    },
  },
  watch: {
    options: {
      async handler() {
        await this.getDataFromApi();
      },
      deep: true,
    },
    async "$route.query.type"() {
      await this.getDataFromApi();
    },
    async "$route.query.status"() {
      await this.getDataFromApi();
    },
    search: debounce(async function (value) {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const { data } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/validations?status=${this.$route.query.status}&type=${this.$route.query.type}&search=${value}&page=${page}&sortBy=${sortBy}&sortDesc=${sortDesc}&itemsPerPage=${itemsPerPage}`,
      });
      this.loading = false;
      let { docs: items } = data;
      const total = data.totalDocs;
      this.total = total;
      this.validations = items;
    }, 500),
  },
};
</script>