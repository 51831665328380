<template>
  <v-row class="text-center" justify="center">
    <v-col cols="12" sm="12" md="12">
      <v-card
        :class="this.$vuetify.breakpoint.mobile ? '' : 'pa-15'"
        :elevation="1"
      >
        <v-container class="text-left">
          <v-row>
            <v-col cols="12" sm="8" class="text-left">
              <img
                src="https://storage.googleapis.com/visual-identity/logo/2020-slim.svg"
                alt="Logo HEIG-VD"
                :height="this.$vuetify.breakpoint.mobile ? 50 : 100"
              />
            </v-col>
            <v-col cols="12" sm="4" class="text-right">
              <div class="title" v-if="!current._id">
                Création de bon de commande
              </div>
              <div class="title" v-else>Édition de bon de commande</div>
              <div class="subtitle-1" v-if="current.orderId">
                E-{{ current.orderId }}-{{ current.creatorTla }}
              </div>
              <div class="subtitle-1 red--text" v-if="current.deletedAt">
                Supprimé le
                {{ format(parseISO(current.deletedAt), "dd.MM.yyyy à HH:mm") }}
              </div>
            </v-col>
          </v-row>
        </v-container>
        <v-card-text>
          <v-text-field
            dense
            outlined
            label="Acronyme employé *"
            :rules="[validation.required, validation.creatorTla]"
            maxlength="3"
            required
            v-model="current.creatorTla"
            class="creatorTla"
          ></v-text-field>
          <v-select
            dense
            outlined
            label="Envoi de la commande"
            :items="buyers"
            v-model="current.buyer"
          ></v-select>
          <v-select
            dense
            outlined
            label="Mode de paiement"
            :items="payments"
            v-model="current.payment"
          ></v-select>
          <v-container class="px-0">
            <v-row>
              <v-col cols="12" md="8">
                <v-autocomplete
                  outlined
                  dense
                  label="Rechercher un fournisseur"
                  :items="suppliers"
                  :item-text="(item) => item.name"
                  :item-value="(item) => item"
                  v-model="selectedSupplier"
                ></v-autocomplete
              ></v-col>
              <v-col cols="12" md="4"
                ><v-btn
                  outlined
                  block
                  @click="createSupplierFromPo"
                  style="margin-top: 2px"
                  :disabled="!!current.deletedAt || current.status !== 'draft'"
                  >Ajouter
                  <v-icon small class="ml-2"
                    >mdi-plus-circle-outline</v-icon
                  ></v-btn
                ></v-col
              >
            </v-row>
          </v-container>
          <v-text-field
            v-model="current.supplier._id"
            disabled
            dense
            outlined
            label="_id"
            v-show="false"
          ></v-text-field>
          <v-text-field
            v-model="current.supplier.name"
            :rules="[validation.required]"
            disabled
            dense
            outlined
            label="Fournisseur *"
          ></v-text-field>
          <v-text-field
            v-model="current.supplier.contact"
            disabled
            dense
            outlined
            label="Personne de contact"
            v-if="current.supplier.contact"
          ></v-text-field>
          <v-text-field
            v-model="current.supplier.phone"
            disabled
            dense
            outlined
            label="Téléphone"
            v-if="current.supplier.phone"
          ></v-text-field>
          <v-text-field
            disabled
            dense
            outlined
            :label="`Adresse e-mail du fournisseur ${
              current.buyer === 'Service Finance' ? '*' : ''
            }`"
            :rules="
              current.buyer !== 'Service Finance'
                ? [validation.supplierEmail]
                : [validation.required, validation.supplierEmail]
            "
            :required="current.buyer === 'Service Finance'"
            v-model="current.supplier.email"
          ></v-text-field>
          <v-text-field
            disabled
            dense
            outlined
            :label="`Adresse e-mail secondaire du fournisseur`"
            v-model="current.supplier.secondEmail"
            v-if="current.supplier.secondEmail"
          ></v-text-field>
          <v-textarea
            disabled
            dense
            outlined
            label="Adresse du fournisseur"
            v-model="current.supplier.address"
            rows="3"
            auto-grow
            required
          ></v-textarea>
          <v-select
            outlined
            dense
            :items="deliveryAddresses"
            v-model="current.delivery"
            label="Adresses de livraison"
            @input="selectType"
          ></v-select>
          <v-textarea
            dense
            outlined
            label="Adresse de livraison *"
            v-model="current.delivery"
            :rules="[validation.required]"
            rows="3"
            auto-grow
            :disabled="selectedAddress !== 'Autre'"
          ></v-textarea>
          <v-autocomplete
            v-model="current.recipient"
            :items="recipients"
            :search-input.sync="searchRecipients"
            label="Destinataire"
            outlined
            dense
            @input="clearSearchRecipients"
            no-data-text="Tapez le nom d'une personne..."
          />
          <v-text-field
            dense
            outlined
            label="No de client"
            v-model="current.clientNumber"
            v-if="!current.supplier._id"
          ></v-text-field>
          <v-select
            label="No de client"
            v-model="current.clientNumber"
            :items="clientNumbers"
            outlined
            dense
            v-else
          >
          </v-select>
          <v-text-field
            dense
            outlined
            label="No et nom du projet"
            v-model="current.projectNumber"
          ></v-text-field>
          <v-container class="text-left pa-0">
            <v-row>
              <v-col cols="12" sm="6" class="text-left">
                <v-text-field
                  dense
                  outlined
                  label="Selon offre réf."
                  v-model="current.offerRef"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="text-right">
                <v-text-field
                  outlined
                  label="du"
                  v-model="displayOfferRefDate"
                  :rules="[validation.date, validation.dateInThePast]"
                  v-mask="['##.##.####']"
                  hint="Format attendu JJ.MM.AAAA"
                  dense
                />
              </v-col>
            </v-row>
          </v-container>
          <v-container class="pa-0">
            <v-row class="my-0">
              <v-col cols="12" sm="10">
                <v-file-input
                  dense
                  outlined
                  accept="application/pdf,image/jpeg,image/jpg,image/jpeg,image/png"
                  v-model="currentOffer"
                  :label="
                    computedValue < 5000
                      ? 'Scan de l\'offre'
                      : 'Scan de l\'offre *'
                  "
                  :rules="
                    computedValue < 5000
                      ? [validation.maxFileSize]
                      : [validation.required, validation.maxFileSize]
                  "
                  @change="handleOfferUpload"
                  @click:clear="removeOffer"
                  :loading="offerUploading"
                  hint="PDF, PNG ou JPEG"
                  :persistent-hint="true"
                ></v-file-input>
              </v-col>
              <v-col
                ><v-btn
                  :disabled="!current.offer"
                  outlined
                  block
                  @click="downloadFile(current.offer)"
                  style="margin-top: 2px"
                  ><v-icon>mdi-cloud-download</v-icon></v-btn
                ></v-col
              >
            </v-row>
            <v-row
              v-for="(otherOffer, i) in otherOffers.filter((x) => x)"
              :key="`otherOffer-${i}`"
              class="my-0"
            >
              <v-col cols="12" sm="8">
                <v-file-input
                  dense
                  :clearable="false"
                  outlined
                  accept="application/pdf,image/jpeg,image/jpg,image/jpeg,image/png"
                  v-model="otherOffers[i]"
                  label="Fichier supplémentaire"
                  :rules="[validation.maxFileSize]"
                  @change="
                    (file) => {
                      handleOtherOffersUpload(file, i);
                    }
                  "
                  :loading="offerUploading"
                  hint="PDF, PNG ou JPEG"
                  :persistent-hint="true"
                ></v-file-input>
              </v-col>
              <v-col
                ><v-btn
                  :disabled="!current.otherOffers[i]"
                  outlined
                  block
                  @click="deleteFile(current.otherOffers[i])"
                  style="margin-top: 2px"
                  ><v-icon>mdi-delete</v-icon></v-btn
                ></v-col
              >
              <v-col
                ><v-btn
                  :disabled="!current.otherOffers[i]"
                  outlined
                  block
                  @click="downloadFile(current.otherOffers[i])"
                  style="margin-top: 2px"
                  ><v-icon>mdi-cloud-download</v-icon></v-btn
                ></v-col
              >
            </v-row>
            <v-row class="my-5">
              <v-col cols="12" sm="12">
                <v-btn block outlined @click="otherOffers.push({})"
                  >Ajouter un fichier (envoyé au fournisseur)</v-btn
                ></v-col
              >
            </v-row>
          </v-container>
          <div class="mb-5">
            <ItemsTable
              :items="current.items"
              :disabled="!!current.deletedAt || current.status !== 'draft'"
            />
          </div>
          <v-container class="text-left pa-0">
            <v-row>
              <v-col cols="12" sm="4" class="text-left">
                <currency-input
                  v-model="current.value"
                  :rules="[validation.required]"
                  :currency="current.currency"
                  :rates="rates"
                  label="Montant *"
                />
              </v-col>
              <v-col cols="12" sm="3" class="text-right">
                <v-select
                  dense
                  outlined
                  label="Devise"
                  v-model="current.currency"
                  :items="currencies"
                  :item-text="(item) => `${item.name} (${item.code})`"
                  item-value="code"
                  :disabled="!current.value"
                  :persistent-hint="
                    current.rates &&
                    !!current.rates.date &&
                    current.currency !== 'CHF'
                  "
                  :hint="
                    current.rates &&
                    `Taux du ${format(
                      new Date(current.rates.date * 1000),
                      'dd.MM.yyyy HH:mm'
                    )}`
                  "
                ></v-select>
              </v-col>
              <v-col class="text-center">
                <v-checkbox
                  label="TTC"
                  hide-details
                  class="mt-1 inline-block mr-5"
                  v-model="current.vatIncluded"
                ></v-checkbox></v-col
              ><v-col
                ><v-checkbox
                  label="Livraison incluse"
                  hide-details
                  class="mt-1 inline-block"
                  v-model="current.deliveryIncluded"
                ></v-checkbox
              ></v-col>
            </v-row>
            <v-row v-if="!current.deliveryIncluded" class="mt-0">
              <v-col cols="12" sm="4" class="text-left">
                <currency-input
                  v-model="current.deliveryFees"
                  :rules="[]"
                  :currency="current.deliveryCurrency"
                  :rates="rates"
                  label="Estimation des frais de livraison (TTC)"
                />
              </v-col>
              <v-col cols="12" sm="3" class="text-right">
                <v-select
                  dense
                  outlined
                  label="Devise"
                  v-model="current.deliveryCurrency"
                  :items="currencies"
                  :item-text="(item) => `${item.name} (${item.code})`"
                  item-value="code"
                  :disabled="!current.deliveryFees"
                  :persistent-hint="
                    current.rates &&
                    !!current.rates.date &&
                    current.currency !== 'CHF'
                  "
                  :hint="
                    current.rates &&
                    `Taux du ${format(
                      new Date(current.rates.date * 1000),
                      'dd.MM.yyyy HH:mm'
                    )}`
                  "
                ></v-select>
              </v-col>
            </v-row>
            <v-row v-if="!current.vatIncluded">
              <v-col cols="12" sm="4" class="text-left">
                <v-text-field
                  outlined
                  dense
                  v-model.number="current.vat"
                  label="Taux TVA (%)"
                  type="number"
                />
              </v-col>
            </v-row>
            <v-row class="mt-0 mb-5">
              <v-col>
                <v-simple-table
                  dense
                  style="border: solid 1px"
                  v-if="current.value"
                >
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td>Montant</td>
                        <td class="text-right">
                          {{
                            new Intl.NumberFormat("fr-CH", {
                              style: "currency",
                              currency: "CHF",
                            }).format(computedValue)
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td>{{ `TVA (${current.vat}%)` }}</td>
                        <td v-if="!current.vatIncluded" class="text-right">
                          {{
                            new Intl.NumberFormat("fr-CH", {
                              style: "currency",
                              currency: "CHF",
                            }).format(computedVat)
                          }}
                        </td>
                        <td v-else class="text-right">incl.</td>
                      </tr>
                      <tr>
                        <td>Estimation des frais de livraison (TTC)</td>
                        <td
                          v-if="
                            !current.deliveryIncluded && current.deliveryFees
                          "
                          class="text-right"
                        >
                          {{
                            new Intl.NumberFormat("fr-CH", {
                              style: "currency",
                              currency: "CHF",
                            }).format(computedDelivery)
                          }}
                        </td>
                        <td v-else class="text-right">incl.</td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold">Total</td>
                        <td class="text-right">
                          {{
                            new Intl.NumberFormat("fr-CH", {
                              style: "currency",
                              currency: "CHF",
                            }).format(computedTotal)
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-container>
          <v-container v-if="current.value >= 100000" class="pa-0">
            <v-row>
              <v-col cols="12" sm="10">
                <v-file-input
                  dense
                  outlined
                  accept="application/pdf,image/jpeg,image/jpg,image/jpeg,image/png"
                  label="Scan du document pour les commandes de plus de CHF 100'000.-"
                  @change="handleDocumentUpload"
                  @click:clear="removeDocument"
                  :loading="documentUploading"
                  hint="PDF, PNG ou JPEG"
                  :persistent-hint="true"
                  v-model="currentDocument"
                  :rules="[validation.maxFileSize]"
                ></v-file-input
              ></v-col>
              <v-col
                ><v-btn
                  :disabled="!current.document"
                  outlined
                  block
                  @click="downloadFile(current.document)"
                  style="margin-top: 2px"
                  ><v-icon>mdi-cloud-download</v-icon></v-btn
                ></v-col
              >
            </v-row>
          </v-container>
          <v-text-field
            dense
            outlined
            label="Année *"
            v-model="current.year"
            :rules="[validation.required, validation.year]"
            maxlength="4"
          ></v-text-field>
          <v-autocomplete
            outlined
            dense
            label="Compte"
            :items="accounts"
            :item-text="(item) => `${item.number} - ${item.name}`"
            :item-value="(item) => item.number"
            v-model="current.account"
          ></v-autocomplete>
          <v-autocomplete
            outlined
            dense
            label="Centre de coûts"
            :items="costcenters"
            :item-text="(item) => `${item.number} - ${item.name}`"
            :item-value="(item) => item.number"
            v-model="current.costCenter"
          ></v-autocomplete>
          <v-text-field
            v-show="false"
            dense
            outlined
            label="Date du bon de commande"
            v-model="displayPoDate"
            :rules="[validation.date]"
            v-mask="['##.##.####']"
            hint="Format attendu JJ.MM.AAAA"
          ></v-text-field>
          <v-textarea
            dense
            outlined
            label="Remarques visibles sur le bon de commande"
            v-model="current.comments"
            rows="3"
            auto-grow
            maxlength="160"
            counter="160"
          ></v-textarea>
          <v-btn
            v-if="!current.customUpload"
            outlined
            block
            @click="addCustomUpload"
            class="mt-4"
            :disabled="!!current.deletedAt || current.status !== 'draft'"
            >Ajouter un fichier personnalisé (pas envoyé au fournisseur)</v-btn
          >
          <div v-if="current.customUpload">
            <v-container class="pa-0">
              <v-row>
                <v-col cols="12" sm="5"
                  ><v-file-input
                    dense
                    outlined
                    accept="application/pdf,image/jpeg,image/jpg,image/jpeg,image/png"
                    :label="current.customUpload.label"
                    @change="handleCustomUpload"
                    @click:clear="removeCustom"
                    :loading="documentUploading"
                    hint="PDF, PNG ou JPEG"
                    :persistent-hint="true"
                    v-model="currentCustom"
                    :rules="[validation.maxFileSize]"
                  ></v-file-input
                ></v-col>
                <v-col cols="12" sm="5">
                  <v-text-field
                    dense
                    outlined
                    label="Label du fichier"
                    v-model="current.customUpload.label"
                  ></v-text-field
                ></v-col>
                <v-col
                  ><v-btn
                    :disabled="!current.customUpload.name"
                    outlined
                    block
                    @click="downloadFile(current.customUpload.name)"
                    style="margin-top: 2px"
                    ><v-icon>mdi-cloud-download</v-icon></v-btn
                  ></v-col
                >
              </v-row>
              <v-row
                v-for="(otherDocument, i) in otherDocuments.filter((x) => x)"
                :key="`otherDocument-${i}`"
                class="my-0"
              >
                <v-col cols="12" sm="8">
                  <v-file-input
                    dense
                    :clearable="false"
                    outlined
                    accept="application/pdf,image/jpeg,image/jpg,image/jpeg,image/png"
                    v-model="otherDocuments[i]"
                    label="Fichier supplémentaire"
                    :rules="[validation.maxFileSize]"
                    @change="
                      (file) => {
                        handleOtherDocumentsUpload(file, i);
                      }
                    "
                    :loading="offerUploading"
                    hint="PDF, PNG ou JPEG"
                    :persistent-hint="true"
                  ></v-file-input>
                </v-col>
                <v-col
                  ><v-btn
                    :disabled="!current.otherDocuments[i]"
                    outlined
                    block
                    @click="deleteFile(current.otherDocuments[i])"
                    style="margin-top: 2px"
                    ><v-icon>mdi-delete</v-icon></v-btn
                  ></v-col
                >
                <v-col
                  ><v-btn
                    :disabled="!current.otherDocuments[i]"
                    outlined
                    block
                    @click="downloadFile(current.otherDocuments[i])"
                    style="margin-top: 2px"
                    ><v-icon>mdi-cloud-download</v-icon></v-btn
                  ></v-col
                >
              </v-row>
              <v-row class="my-5">
                <v-col cols="12" sm="12">
                  <v-btn
                    block
                    outlined
                    @click="otherDocuments.push({})"
                    :disabled="
                      !current.customUpload.name || !current.customUpload.label
                    "
                    >Ajouter un fichier (pas envoyé au fournisseur)</v-btn
                  ></v-col
                >
              </v-row>
            </v-container>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            v-if="this.$route.name === 'Orders Details'"
            to="/orders/create"
            >Nouveau bon de commande</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {
  parse,
  parseISO,
  format,
  getYear,
  formatISO,
  isSameDay,
} from "date-fns";
import axios from "axios";
import FileDownload from "js-file-download";
import { mask } from "vue-the-mask";
import ItemsTable from "@/components/ItemsTable";
import CurrencyInput from "./CurrencyInput.vue";
import Intl from "intl";
import isAfter from "date-fns/isAfter/index";
import _ from "lodash";

export default {
  directives: { mask },
  components: { ItemsTable, CurrencyInput },
  async mounted() {
    try {
      const { data: suppliers } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/suppliers`,
      });
      this.suppliers = suppliers;
      const { data: accounts } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/accounts`,
      });
      this.accounts = accounts;
      const { data: costcenters } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/costcenters`,
      });
      this.costcenters = costcenters;
      const { data: addresses } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/addresses`,
      });
      this.deliveryAddresses = [
        ...addresses,
        {
          text: "Autre",
          value: "",
        },
      ];
    } catch (error) {
      console.log(error);
    }
    if (this.current) {
      if (
        this.deliveryAddresses
          .map((da) => da.value)
          .indexOf(this.current.delivery) === -1
      ) {
        this.deliveryAddresses.find((da) => da.text === "Autre").value = "";
      }
      if (this.current.offerRefDate) {
        this.displayOfferRefDate = format(
          parseISO(this.current.offerRefDate),
          "dd.MM.yyyy"
        );
      }
      if (this.current.offer) {
        this.currentOffer = new File([], this.current.offer.substr(22), {
          type: "text-plain",
        });
      }
      if (this.current.otherOffers && this.current.otherOffers.length) {
        this.otherOffers = this.current.otherOffers.map((oo) => {
          return new File([], oo.substr(22), {
            type: "text-plain",
          });
        });
      }
      if (this.current.otherDocuments && this.current.otherDocuments.length) {
        this.otherDocuments = this.current.otherDocuments.map((oo) => {
          return new File([], oo.substr(22), {
            type: "text-plain",
          });
        });
      }
      if (this.current.document) {
        this.currentDocument = new File([], this.current.document.substr(22), {
          type: "text-plain",
        });
      }
      if (this.current.customUpload && this.current.customUpload.name) {
        this.currentCustom = new File(
          [],
          this.current.customUpload.name.substr(22),
          {
            type: "text-plain",
          }
        );
      }
      if (this.current.recipient) {
        this.recipients = [this.current.recipient];
      }
      if (this.current.supplier && this.current.supplier._id) {
        this.clientNumbers = this.suppliers.find(
          (s) => s._id === this.current.supplier._id
        ).clientNumbers;
      }
    }
  },
  props: [
    "current",
    "generating",
    "computedValue",
    "computedVat",
    "computedDelivery",
    "computedTotal",
    "requiredFields",
    "rates",
  ],
  data: () => ({
    searchRecipients: null,
    recipients: [],
    selectedAddress: "",
    currentOffer: undefined,
    otherOffers: [],
    currentDocument: undefined,
    otherDocuments: [],
    currentCustom: undefined,
    offerUploading: false,
    documentUploading: false,
    displayPoDate: "",
    displayOfferRefDate: "",
    formattedValue: null,
    formattedValueCurrency: null,
    suppliers: [],
    accounts: [],
    costcenters: [],
    selectedSupplier: {},
    clientNumbers: [],
    buyers: [
      {
        text: "Commande transmise automatiquement au fournisseur",
        value: "Automatique",
      },
      {
        text: "Je souhaite passer la commande moi-même",
        value: "Manuel (traité par le requérant)",
      },
    ],
    payments: ["Facture", "Proforma", "Carte de crédit", "Note de frais"],
    currencies: [
      { name: "Franc suisse", code: "CHF" },
      { name: "Euro", code: "EUR" },
      { name: "Dollar américain", code: "USD" },
      { name: "Livre sterling", code: "GBP" },
    ],
    deliveryAddresses: [
      {
        text: "Autre",
        value: "",
      },
    ],
    validation: {
      required: (value) => !!value || "Ce champ est requis.",
      creatorTla: (value) => {
        const pattern = /^[a-zA-Z]*$/;
        return (
          (pattern.test(value) && value.length === 3) ||
          "Votre acronyme est composé de 3 lettres."
        );
      },
      supplierEmail: (value) => {
        if (value) {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Adresse e-mail non valide.";
        }
        return true;
      },
      value: (value) => {
        const pattern = /^\d+(\d{3})*(\.\d{2,2})?$/;
        return pattern.test(value) || "Veuillez respecter le format 00.00";
      },
      year: (value) => {
        const pattern = /^[0-9]*$/;
        return (
          (pattern.test(value) &&
            value.length === 4 &&
            getYear(new Date()) <= value) ||
          "L'année doit être composé de 4 chiffres et postérieure ou égale à l'année en cours."
        );
      },
      date: (value) => {
        if (value) {
          const parsed = parse(value, "dd.MM.yyyy", new Date());
          if (parsed.toString() !== "Invalid Date" && value.length === 10) {
            return true;
          }
          return "Format attendu JJ.MM.AAAA";
        }
        return true;
      },
      dateInThePast: (value) => {
        if (value) {
          const parsed = parse(value, "dd.MM.yyyy", new Date());
          if (
            parsed.toString() !== "Invalid Date" &&
            value.length === 10 &&
            !isAfter(parsed, new Date())
          ) {
            return true;
          }
          return "La date ne peut pas être dans le futur";
        }
        return true;
      },
      maxFileSize(value) {
        return !value || value.size < 4000000 || "Taille maximale 4 MB";
      },
    },
    format,
    parseISO,
    Intl,
  }),
  methods: {
    clearSearchRecipients() {
      this.searchRecipients = null;
    },
    async queryPicker(v, key) {
      this.loadingPpl = true;
      const { data: contacts } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_PEOPLEPICKER_URI}/search-employees?s=${v}`,
        headers: {
          "x-api-key": process.env.VUE_APP_PEOPLEPICKER_KEY,
        },
      });
      contacts.map((c) =>
        this[key].push({
          text: `${c.firstname} ${c.lastname}`,
          value: `${c.firstname} ${c.lastname}`,
        })
      );
      this.loadingPpl = false;
    },
    createSupplierFromPo() {
      localStorage.setItem("po", JSON.stringify(this.current));
      this.$router.push("/suppliers/create?from=po");
    },
    selectType(value) {
      this.selectedAddress = this.deliveryAddresses.find(
        (da) => da.value === value
      ).text;
    },
    addCustomUpload() {
      this.current.customUpload = { name: "", label: "" };
    },
    handleOfferUpload: async function (file) {
      if (file) {
        this.offerUploading = false;
        try {
          const formData = new FormData();
          formData.append("file", file);
          const { data } = await axios({
            method: "post",
            url: `${process.env.VUE_APP_API_URI}/upload`,
            data: formData,
            headers: {
              "content-Type": "multipart/form-data",
            },
          });
          this.current.offer = data.filename;
          this.offerUploading = false;
        } catch (e) {
          this.offerUploading = false;
        }
      }
    },
    handleOtherOffersUpload: async function (file, i) {
      if (file) {
        this.offerUploading = false;
        try {
          const formData = new FormData();
          formData.append("file", file);
          const { data } = await axios({
            method: "post",
            url: `${process.env.VUE_APP_API_URI}/upload`,
            data: formData,
            headers: {
              "content-Type": "multipart/form-data",
            },
          });
          this.current.otherOffers[i] = data.filename;
          this.offerUploading = false;
        } catch (e) {
          this.offerUploading = false;
        }
      }
    },
    handleOtherDocumentsUpload: async function (file, i) {
      if (file) {
        this.offerUploading = false;
        try {
          const formData = new FormData();
          formData.append("file", file);
          const { data } = await axios({
            method: "post",
            url: `${process.env.VUE_APP_API_URI}/upload`,
            data: formData,
            headers: {
              "content-Type": "multipart/form-data",
            },
          });
          this.current.otherDocuments[i] = data.filename;
          this.offerUploading = false;
        } catch (e) {
          this.offerUploading = false;
        }
      }
    },
    handleDocumentUpload: async function (file) {
      if (file) {
        this.documentUploading = false;
        try {
          const formData = new FormData();
          formData.append("file", file);
          const { data } = await axios({
            method: "post",
            url: `${process.env.VUE_APP_API_URI}/upload`,
            data: formData,
            headers: {
              "content-Type": "multipart/form-data",
            },
          });
          this.current.document = data.filename;
          this.documentUploading = false;
        } catch (e) {
          this.documentUploading = false;
        }
      }
    },
    handleCustomUpload: async function (file) {
      if (file) {
        this.documentUploading = false;
        try {
          const formData = new FormData();
          formData.append("file", file);
          const { data } = await axios({
            method: "post",
            url: `${process.env.VUE_APP_API_URI}/upload`,
            data: formData,
            headers: {
              "content-Type": "multipart/form-data",
            },
          });
          this.current.customUpload.name = data.filename;
          this.documentUploading = false;
        } catch (e) {
          this.documentUploading = false;
        }
      }
    },
    removeCustom: function () {
      this.current.customUpload.name = "";
    },
    removeDocument: function () {
      this.current.document = "";
    },
    removeOffer: function () {
      this.current.offer = "";
    },
    deleteFile: function (filename) {
      this.current.otherOffers = this.current.otherOffers.filter(
        (oo) => oo !== filename
      );
      this.otherOffers = this.current.otherOffers.map((oo) => {
        return new File([], oo.substr(22), {
          type: "text-plain",
        });
      });
    },
    downloadFile: async function (filename) {
      const { data } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/files/${filename}`,
        responseType: "arraybuffer",
      });
      FileDownload(data, filename);
    },
  },
  watch: {
    searchRecipients: _.debounce(function (val) {
      val && val !== this.selected && this.queryPicker(val, "recipients");
    }, 300),
    selectedSupplier(val) {
      if (Object.keys(val).length) {
        this.current.supplier._id = val._id;
        this.current.supplier.email = val.email;
        this.current.supplier.secondEmail = val.secondEmail;
        this.current.supplier.address =
          `${val.addressOne}\n${val.addressTwo}\n${val.zip} ${val.city}\n${val.country}`
            .replace(/(\r\n|\r|\n){2}/g, "$1")
            .replace(/(\r\n|\r|\n){3,}/g, "$1\n");
        if (this.current.supplier.address === "\n \n") {
          this.current.supplier.address = "";
        }
        this.current.supplier.name = val.name;
        this.current.supplier.contact = val.contact;
        this.current.supplier.phone = val.phone;
        this.clientNumbers = val.clientNumbers;
        this.selectedSupplier = {};
      }
    },
    "current.delivery": function (val) {
      if (this.deliveryAddresses.map((da) => da.value).indexOf(val) === -1) {
        this.deliveryAddresses.find((da) => da.text === "Autre").value = val;
      }
    },
    "current.creatorTla": function (val) {
      this.current.creatorTla = val.toUpperCase();
    },
    displayOfferRefDate: function (after) {
      if (after.length === 10) {
        this.current.offerRefDate = formatISO(
          parse(after, "dd.MM.yyyy", new Date())
        );
      }
    },
    "current.offerRefDate": function (after, before) {
      if (after && !isSameDay(parseISO(after), parseISO(before))) {
        this.displayOfferRefDate = format(parseISO(after), "dd.MM.yyyy");
      }
    },
  },
};
</script>

<style scoped>
.inline-block {
  display: inline-block;
}
</style>