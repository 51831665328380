<template>
  <div id="embedpdf"></div>
</template>

<script>
import axios from "axios";
import PDFObject from "pdfobject";

export default {
  props: ["filename"],
  data() {
    return {
      numPages: 0,
      loading: false,
      pdfsrc: null,
      config: {
        toolbar: false,
      },
    };
  },
  watch: {
    async filename(val) {
      URL.revokeObjectURL(this.pdfsrc);
      this.loading = true;
      try {
        const response = await axios({
          method: "get",
          url: `${process.env.VUE_APP_API_URI}/files/${val}?type=display`,
          responseType: "blob",
        });
        const objectUrl = URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        this.pdfsrc = objectUrl;
        PDFObject.embed(objectUrl, "#embedpdf", { height: "100vh" });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    this.loading = true;
    try {
      const response = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/files/${this.filename}?type=display`,
        responseType: "blob",
      });
      const objectUrl = URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );
      this.pdfsrc = objectUrl;
      PDFObject.embed(objectUrl, "#embedpdf", { height: "100vh" });
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  },
  beforeDestroy() {
    URL.revokeObjectURL(this.filename);
  },
};
</script>

<style>
.canvasWrapper {
  width: auto !important;
  height: -webkit-fill-available !important;
}
.pdf-app.light {
  --pdf-app-background-color: white;
}
</style>
