<template>
  <div>
    <div class="subtitle-2">E-mail fournisseur</div>
    <div>
      À :
      <a :href="`mailto:${validation.order.supplier.email}`">{{
        validation.order.supplier.email
      }}</a>
      <span v-if="validation.order.supplier.secondEmail">
        ,
        <a :href="`mailto:${validation.order.supplier.secondEmail}`">{{
          validation.order.supplier.secondEmail
        }}</a>
      </span>
    </div>
    <div>
      Cc :
      <a :href="`mailto:${validation.order.createdBy.email}`">{{
        validation.order.createdBy.email
      }}</a
      >,
      <a href="mailto:commande@heig-vd.ch">commande@heig-vd.ch</a>
    </div>

    <div>
      Objet : HEIG-VD - Commande E-{{ validation.order.orderId }}-{{
        validation.order.creatorTla
      }}
    </div>
    <tiptap-vuetify v-model="content" :extensions="extensions" class="mt-5" />
    <div class="mt-1">
      NB : un document PDF contenant le bon de commande ainsi que le scan de
      l'offre sera ajouté en pièce jointe
    </div>
  </div>
</template>

<script>
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";

export default {
  components: { TiptapVuetify },
  props: ["validation"],
  data: () => ({
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3],
          },
        },
      ],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak,
    ],
    content: "",
  }),
  mounted() {
    this.content = `<p>Madame, Monsieur,</p>
                <p>Nous vous passons commande selon le bordereau N° E-${this.validation.order.orderId}-${this.validation.order.creatorTla} en pièce jointe.</p>
                <p>Pour tout renseignement ou information complémentaire, nous vous prions de contacter directement ${this.validation.order.createdBy.name} qui nous lit en copie.</p>
                <p>Avec nos remerciements et meilleurs messages</p>
                <p>Notification automatique</p>`;
  },
};
</script>
