<template>
  <div>
    <div
      class="text-center text-subtitle-2 mt-4"
      v-if="
        !po.creatorTla ||
        po.creatorTla.length !== 3 ||
        !po.supplier.name ||
        !po.delivery ||
        !po.value ||
        !po.year ||
        (computedValue && computedValue >= 5000 && !po.offer)
      "
    >
      champs requis
    </div>
    <ul>
      <li v-if="!po.creatorTla || po.creatorTla.length !== 3">
        Acronyme employé
      </li>
      <li v-if="!po.supplier.name">Fournisseur</li>
      <li v-if="!po.delivery">Adresse de livraison</li>
      <li v-if="!po.value">Montant</li>
      <li v-if="!po.year">Année</li>
      <li v-if="computedValue && computedValue >= 5000 && !po.offer">
        Scan de l'offre
      </li>
      <li v-if="po.items.length === 0">Au moins un élément</li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["po", "computedValue"],
};
</script>
