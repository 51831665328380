import Vue from "vue";
import VueRouter from "vue-router";
import PurchaseOrderCreate from "../views/purchase-orders/Create.vue";
import PurchaseOrderUpdate from "../views/purchase-orders/Update.vue";
import PurchaseOrderList from "../views/purchase-orders/List.vue";
import SupplierList from "../views/suppliers/List.vue";
import SupplierCreate from "../views/suppliers/Create.vue";
import SupplierUpdate from "../views/suppliers/Update.vue";
import ValidationUpdate from "../views/validations/Update.vue";
import ValidationUpdatePo from "../views/validations/UpdatePo.vue";
import ValidationList from "../views/validations/List.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/orders/create",
  },
  {
    path: "/orders",
    name: "PurchaseOrderList",
    component: PurchaseOrderList,
  },
  {
    path: "/orders/create",
    name: "PurchaseOrderCreate",
    component: PurchaseOrderCreate,
  },
  {
    path: "/orders/:_id/validations/:validation_id",
    name: "ValidationUpdatePo",
    component: ValidationUpdatePo,
  },
  {
    path: "/orders/:_id/validations",
    name: "ValidationUpdate",
    component: ValidationUpdate,
  },
  {
    path: "/orders/:_id",
    name: "PurchaseOrderUpdate",
    component: PurchaseOrderUpdate,
  },
  {
    path: "/validations",
    name: "ValidationList",
    component: ValidationList,
  },
  {
    path: "/suppliers",
    name: "SupplierList",
    component: SupplierList,
  },
  {
    path: "/suppliers/create",
    name: "SupplierCreate",
    component: SupplierCreate,
  },
  {
    path: "/suppliers/:_id",
    name: "SupplierUpdate",
    component: SupplierUpdate,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
