import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import VueKeyCloak from "@dsb-norge/vue-keycloak-js";
import axios from "axios";
import router from "./router";
import VueCurrencyInput from "vue-currency-input";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

Vue.use(VueCurrencyInput);

Vue.config.productionTip = false;

if (process.env.VUE_APP_ENV === "production") {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["127.0.0.1", "bc-next.netlify.app", /^\//],
      }),
    ],
    tracesSampleRate: 0.4,
  });
}

const tokenInterceptor = () => {
  axios.interceptors.request.use(
    (config) => {
      if (config.url.indexOf("https://api.exchangeratesapi.io") === -1) {
        config.headers.Authorization = `Bearer ${Vue.prototype.$keycloak.token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

const isChrome =
  /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
const init = { onLoad: "login-required" };
if (!isChrome) {
  init.checkLoginIframe = false;
}
Vue.use(VueKeyCloak, {
  init,
  config: {
    realm: process.env.VUE_APP_KC_REALM,
    url: process.env.VUE_APP_KC_URL,
    clientId: process.env.VUE_APP_KC_CLIENT_ID,
  },
  onReady: async (keycloak) => {
    tokenInterceptor();
    try {
      const response = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/user-context`,
      });
      if (response.status === 200) {
        new Vue({
          vuetify,
          router,
          render: (h) => h(App),
        }).$mount("#app");
      } else {
        keycloak.logout();
      }
    } catch (error) {
      keycloak.logout();
    }
  },
});
