var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      !_vm.po.creatorTla ||
      _vm.po.creatorTla.length !== 3 ||
      !_vm.po.supplier.name ||
      !_vm.po.delivery ||
      !_vm.po.value ||
      !_vm.po.year ||
      (_vm.computedValue && _vm.computedValue >= 5000 && !_vm.po.offer)
    )?_c('div',{staticClass:"text-center text-subtitle-2 mt-4"},[_vm._v(" champs requis ")]):_vm._e(),_c('ul',[(!_vm.po.creatorTla || _vm.po.creatorTla.length !== 3)?_c('li',[_vm._v(" Acronyme employé ")]):_vm._e(),(!_vm.po.supplier.name)?_c('li',[_vm._v("Fournisseur")]):_vm._e(),(!_vm.po.delivery)?_c('li',[_vm._v("Adresse de livraison")]):_vm._e(),(!_vm.po.value)?_c('li',[_vm._v("Montant")]):_vm._e(),(!_vm.po.year)?_c('li',[_vm._v("Année")]):_vm._e(),(_vm.computedValue && _vm.computedValue >= 5000 && !_vm.po.offer)?_c('li',[_vm._v(" Scan de l'offre ")]):_vm._e(),(_vm.po.items.length === 0)?_c('li',[_vm._v("Au moins un élément")]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }