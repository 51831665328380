<template>
  <v-container :fluid="$vuetify.breakpoint.lgAndDown">
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <div>Mes BC</div>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Rechercher..."
              single-line
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="computedHeaders"
            :options.sync="options"
            :server-items-length="total"
            :loading="loading"
            :items="orders"
            :search="search"
            class="elevation-1"
            :footer-props="{
              itemsPerPageOptions: [10, 30, 50],
              itemsPerPageText: 'Élements par page',
            }"
            @pagination="paginationUpdate"
          >
            <!-- eslint-disable -->
            <template
              v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }"
              >{{ pageStart }}-{{ pageStop }} de {{ itemsLength }}</template
            >
            <!-- eslint-enable -->
            <template v-slot:body="{ items }">
              <tbody class="text-left cursor-pointer">
                <tr
                  v-for="item in items"
                  :key="item._id"
                  @click="
                    $route &&
                    $route.query &&
                    $route.query.status &&
                    ['to-order', 'to-order-contractor'].indexOf(
                      $route.query.status
                    ) !== -1
                      ? $router.push(`/orders/${item._id}/validations`)
                      : $router.push(`/orders/${item._id}`)
                  "
                >
                  <td
                    :class="
                      item.deletedAt ? 'text-decoration-line-through' : ''
                    "
                  >
                    E-{{ item.orderId }}-{{ item.creatorTla }}
                  </td>
                  <td
                    :class="
                      item.deletedAt ? 'text-decoration-line-through' : ''
                    "
                  >
                    {{ item.supplier.name }}
                  </td>
                  <td
                    :class="
                      item.deletedAt ? 'text-decoration-line-through' : ''
                    "
                  >
                    {{
                      new Intl.NumberFormat("fr-CH", {
                        style: "currency",
                        currency: "CHF",
                      }).format(item.computedTotal)
                    }}
                  </td>
                  <td
                    :class="
                      item.deletedAt ? 'text-decoration-line-through' : ''
                    "
                  >
                    {{ item.year }}
                  </td>
                  <td
                    :class="
                      item.deletedAt ? 'text-decoration-line-through' : ''
                    "
                  >
                    {{ format(parseISO(item.poDate), "dd.MM.yyyy") }}
                  </td>
                  <td
                    :class="
                      item.deletedAt ? 'text-decoration-line-through' : ''
                    "
                  >
                    {{ item.projectNumber }}
                  </td>
                  <td
                    class="text-center"
                    v-if="$route.query.status === 'draft'"
                  >
                    <v-btn
                      v-if="!item.deletedAt && deleteConfirmation !== item._id"
                      small
                      text
                      @click.stop="setDeleteConfirmation(item._id)"
                      >Supprimer</v-btn
                    >
                    <v-btn
                      v-if="!item.deletedAt && deleteConfirmation === item._id"
                      small
                      color="error"
                      text
                      @click.stop="deleteItem(item._id)"
                      >Confirmer</v-btn
                    >
                    <div v-if="item.deletedAt">
                      {{ format(parseISO(item.deletedAt), "dd.MM.yyyy HH:mm") }}
                    </div>
                  </td>
                  <td v-else>
                    {{ item.lastValidation && item.lastValidation.fullname }} -
                    {{
                      item.lastValidation &&
                      format(
                        parseISO(item.lastValidation.createdAt),
                        "dd.MM.yyyy"
                      )
                    }}
                  </td>
                </tr>
              </tbody></template
            >
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" top right>
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.visible = false">
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import { debounce } from "lodash";
import Intl from "intl";
import { format, parseISO } from "date-fns";

export default {
  props: ["updateBadge", "setBadge"],
  data: () => ({
    snackbar: {
      visible: false,
      color: "",
      text: "",
    },
    orders: undefined,
    search: "",
    loading: false,
    deleteConfirmation: "",
    total: 0,
    deletedAt: false,
    options: {},
    headers: [
      {
        text: "N° BC",
        align: "start",
        value: "orderId",
      },
      { text: "Fournisseur", value: "supplier.name" },
      { text: "Montant", value: "computedTotal" },
      { text: "Année", value: "year" },
      { text: "Date de création", value: "poDate" },
      { text: "No et nom du projet", value: "projectNumber" },
      { text: "Suppression", sortable: false, align: "center" },
      { text: "En validation chez", sortable: false, align: "left" },
    ],
    Intl,
    format,
    parseISO,
  }),
  methods: {
    paginationUpdate(value) {
      if (this.$route.query && this.$route.query.status) {
        if (this.$route.query.status === "to-order") {
          this.setBadge("toOrder", value.itemsLength);
        }
        if (this.$route.query.status === "processing") {
          this.setBadge("processing", value.itemsLength);
        }
      }
    },
    async getDataFromApi() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const { data } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/orders?page=${page}&sortBy=${sortBy}&sortDesc=${sortDesc}&itemsPerPage=${itemsPerPage}&search=${this.search}&deletedAt=${this.deletedAt}&status=${this.$route.query.status}`,
      });
      this.loading = false;
      let { docs: items } = data;
      const total = data.totalDocs;
      this.orders = items;
      this.total = total;
    },
    setDeleteConfirmation(_id) {
      this.deleteConfirmation = _id;
    },
    async deleteItem() {
      try {
        const { data: deleted } = await axios({
          method: "delete",
          url: `${process.env.VUE_APP_API_URI}/orders/${this.deleteConfirmation}`,
        });
        this.orders.find((o) => o._id === this.deleteConfirmation).deletedAt =
          deleted.deletedAt;
        const status = this.orders.find(
          (o) => o._id === this.deleteConfirmation
        ).status;
        if (status === "draft") {
          this.updateBadge("drafts", -1);
        }
        if (status === "to-order") {
          this.updateBadge("toOrder", -1);
        }
        if (!this.deletedAt) {
          await this.getDataFromApi();
        }

        this.snackbar = {
          visible: true,
          color: "success",
          text: "Élément supprimé",
        };
      } catch (error) {
        this.snackbar = {
          visible: true,
          color: "error",
          text: "Erreur de suppression",
        };
      } finally {
        this.deleteConfirmation = "";
      }
    },
  },
  watch: {
    async "$route.query.status"() {
      await this.getDataFromApi();
    },
    options: {
      async handler() {
        await this.getDataFromApi();
      },
      deep: true,
    },
    async deletedAt() {
      await this.getDataFromApi();
    },
    search: debounce(async function (value) {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const { data } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/orders?page=${page}&sortBy=${sortBy}&sortDesc=${sortDesc}&itemsPerPage=${itemsPerPage}&search=${value}&deletedAt=${this.deletedAt}&status=${this.$route.query.status}`,
      });
      this.loading = false;
      let { docs: items } = data;
      const total = data.totalDocs;
      this.orders = items;
      this.total = total;
    }, 500),
  },
  computed: {
    computedHeaders() {
      return this.$route.query.status !== "draft"
        ? this.headers.filter((h) => h.text !== "Suppression")
        : this.headers.filter((h) => h.text !== "En validation chez");
    },
  },
};
</script>