<template>
  <div>
    <v-card v-for="i in numPages" :key="i" class="mb-3">
      <v-card-text>
        <pdf :src="pdfsrc" :page="i"></pdf>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import pdf from "vue-pdf";

export default {
  components: {
    pdf,
  },
  props: ["filename"],
  data() {
    return {
      numPages: 0,
      loading: false,
      pdfsrc: null,
      config: {
        toolbar: false,
      },
    };
  },
  watch: {
    async filename(val) {
      URL.revokeObjectURL(this.pdfsrc);
      this.loading = true;
      try {
        const { data } = await axios({
          method: "get",
          url: `${process.env.VUE_APP_API_URI}/files/${val}?type=display`,
          responseType: "blob",
        });
        const blob = new Blob([data]);
        const objectUrl = URL.createObjectURL(blob);
        this.pdfsrc = objectUrl;
        pdf.createLoadingTask(objectUrl).promise.then((pdf) => {
          this.numPages = pdf.numPages;
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    this.loading = true;
    try {
      const { data } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/files/${this.filename}?type=display`,
        responseType: "blob",
      });
      const blob = new Blob([data]);
      const objectUrl = URL.createObjectURL(blob);
      this.pdfsrc = objectUrl;
      pdf.createLoadingTask(objectUrl).promise.then((pdf) => {
        this.numPages = pdf.numPages;
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  },
  beforeDestroy() {
    URL.revokeObjectURL(this.filename);
  },
};
</script>

<style>
.canvasWrapper {
  width: auto !important;
  height: -webkit-fill-available !important;
}
.pdf-app.light {
  --pdf-app-background-color: white;
}
</style>