<template>
  <v-form ref="form" v-model="valid" lazy-validation v-if="supplier">
    <v-row class="text-center" justify="center">
      <v-col cols="12" sm="12" md="12">
        <v-card :elevation="1">
          <v-container class="text-left" fluid>
            <v-row>
              <v-col>
                <v-btn
                  text
                  small
                  plain
                  class="mt-2"
                  @click="$router.go(steps || -1)"
                  :ripple="false"
                >
                  <v-icon small class="mr-2">mdi-arrow-left</v-icon
                  >retour</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
          <v-divider class="mb-2"></v-divider>
          <v-card-title
            :class="this.$vuetify.breakpoint.mobile ? '' : 'px-15 py-10'"
            >{{
              supplier._id
                ? "Mettre à jour un fournisseur"
                : "Créer un fournisseur"
            }}
          </v-card-title>
          <v-card-text :class="this.$vuetify.breakpoint.mobile ? '' : 'px-15'">
            <v-text-field
              outlined
              dense
              v-model="supplier.name"
              label="Nom *"
              :rules="[validation.required]"
            ></v-text-field>
            <v-text-field
              outlined
              dense
              v-model="supplier.contact"
              label="Personne de contact"
            ></v-text-field>
            <v-text-field
              outlined
              dense
              v-model="supplier.addressOne"
              label="Adresse 1"
            ></v-text-field>
            <v-text-field
              outlined
              dense
              v-model="supplier.addressTwo"
              label="Adresse 2"
            ></v-text-field>
            <v-text-field
              outlined
              dense
              v-model="supplier.zip"
              label="NPA"
            ></v-text-field>
            <v-text-field
              outlined
              dense
              v-model="supplier.city"
              label="Ville"
            ></v-text-field>
            <v-autocomplete
              outlined
              dense
              :items="countries"
              v-model="supplier.country"
              label="Pays"
            ></v-autocomplete>
            <v-text-field
              outlined
              dense
              v-model="supplier.email"
              :rules="[validation.email]"
              label="E-mail"
              ref="primaryEmail"
            ></v-text-field>
            <v-text-field
              outlined
              dense
              v-model="supplier.secondEmail"
              :rules="[validation.email]"
              label="E-mail secondaire"
              :disabled="
                !supplier.email ||
                (this.$refs.primaryEmail && !this.$refs.primaryEmail.valid)
              "
            ></v-text-field>
            <v-text-field
              outlined
              dense
              v-model="supplier.phone"
              label="Téléphone"
            ></v-text-field>
            <v-combobox
              v-model="supplier.clientNumbers"
              chips
              clearable
              label="Numéros de client"
              multiple
              outlined
              dense
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  @click="select"
                  @click:close="remove(item)"
                  small
                >
                  {{ item }}
                </v-chip>
              </template>
            </v-combobox>
          </v-card-text>
          <v-card-actions
            :class="this.$vuetify.breakpoint.mobile ? '' : 'px-15 pb-5'"
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              :disabled="!requiredFields || !valid"
              :loading="loading"
              @click="submit"
              >{{ supplier._id ? "Mettre à jour" : "Créer" }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  props: ["supplier", "loading", "submit", "steps"],
  methods: {
    remove(item) {
      this.supplier.clientNumbers.splice(
        this.supplier.clientNumbers.indexOf(item),
        1
      );
    },
  },
  computed: {
    requiredFields: function () {
      return !!this.supplier.name;
    },
  },
  data: () => ({
    valid: false,
    validation: {
      required: (value) => !!value || "Ce champ est requis.",
      email: (value) => {
        if (value) {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Adresse e-mail non valide.";
        }
        return true;
      },
    },
    countries: [
      "Afghanistan",
      "Afrique du Sud",
      "Albanie",
      "Algérie",
      "Allemagne",
      "Andorre",
      "Angola",
      "Antigua-et-Barbuda",
      "Arabie saoudite",
      "Argentine",
      "Arménie",
      "Australie",
      "Autriche",
      "Azerbaïdjan",
      "Bahamas",
      "Bahreïn",
      "Bangladesh",
      "Barbade",
      "Biélorussie",
      "Belgique",
      "Belize",
      "Bénin",
      "Bhoutan",
      "Bolivie",
      "Bosnie-Herzégovine",
      "Botswana",
      "Brésil",
      "Brunei",
      "Bulgarie",
      "Burkina Faso",
      "Burundi",
      "Cambodge",
      "Cameroun",
      "Canada",
      "Cap-Vert",
      "République centrafricaine",
      "Chili",
      "Chine",
      "Chypre (pays)",
      "Colombie",
      "Comores (pays)",
      "République du Congo",
      "République démocratique du Congo",
      "Corée du Sud",
      "Corée du Nord",
      "Costa Rica",
      "Côte d'Ivoire",
      "Croatie",
      "Cuba",
      "Danemark",
      "Djibouti",
      "République dominicaine",
      "Dominique",
      "Égypte",
      "Salvador",
      "Émirats arabes unis",
      "Équateur (pays)",
      "Érythrée",
      "Espagne",
      "Estonie",
      "États-Unis",
      "Éthiopie",
      "Fidji",
      "Finlande",
      "France",
      "Gabon",
      "Gambie",
      "Géorgie (pays)",
      "Ghana",
      "Grèce",
      "Grenade (pays)",
      "Guatemala",
      "Guinée",
      "Guinée-Bissau",
      "Guinée équatoriale",
      "Guyana",
      "Haïti",
      "Honduras",
      "Hongrie",
      "Inde",
      "Indonésie",
      "Iran",
      "Irak",
      "Irlande (pays)",
      "Islande",
      "Israël",
      "Italie",
      "Jamaïque",
      "Japon",
      "Jordanie",
      "Kazakhstan",
      "Kenya",
      "Kirghizistan",
      "Kiribati",
      "Koweït",
      "Laos",
      "Lesotho",
      "Lettonie",
      "Liban",
      "Liberia",
      "Libye",
      "Liechtenstein",
      "Lituanie",
      "Luxembourg (pays)",
      "Macédoine du Nord",
      "Madagascar",
      "Malaisie",
      "Malawi",
      "Maldives",
      "Mali",
      "Malte",
      "Maroc",
      "Îles Marshall (pays)",
      "Maurice (pays)",
      "Mauritanie",
      "Mexique",
      "États fédérés de Micronésie (pays)",
      "Moldavie",
      "Monaco",
      "Mongolie",
      "Monténégro",
      "Mozambique",
      "Birmanie",
      "Namibie",
      "Nauru",
      "Népal",
      "Nicaragua",
      "Niger",
      "Nigeria",
      "Norvège",
      "Nouvelle-Zélande",
      "Oman",
      "Ouganda",
      "Ouzbékistan",
      "Pakistan",
      "Palaos",
      "Panama",
      "Papouasie-Nouvelle-Guinée",
      "Paraguay",
      "Pays-Bas",
      "Pérou",
      "Philippines",
      "Pologne",
      "Portugal",
      "Qatar",
      "Roumanie",
      "Royaume-Uni",
      "Russie",
      "Rwanda",
      "Saint-Christophe-et-Niévès",
      "Saint-Marin",
      "Saint-Vincent-et-les-Grenadines",
      "Sainte-Lucie",
      "Îles Salomon",
      "Samoa",
      "Sao Tomé-et-Principe",
      "Sénégal",
      "Serbie",
      "Seychelles",
      "Sierra Leone",
      "Singapour",
      "Slovaquie",
      "Slovénie",
      "Somalie",
      "Soudan",
      "Soudan du Sud",
      "Sri Lanka",
      "Suède",
      "Suisse",
      "Suriname",
      "Eswatini",
      "Syrie",
      "Tadjikistan",
      "Tanzanie",
      "Tchad",
      "Tchéquie",
      "Thaïlande",
      "Timor oriental",
      "Togo",
      "Tonga",
      "Trinité-et-Tobago",
      "Tunisie",
      "Turkménistan",
      "Turquie",
      "Tuvalu",
      "Ukraine",
      "Uruguay",
      "Vanuatu",
      "Venezuela",
      "Viêt Nam",
      "Yémen",
      "Zambie",
      "Zimbabwe",
    ],
  }),
};
</script>