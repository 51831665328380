var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":_vm.$vuetify.breakpoint.lgAndDown}},[_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',[_c('div',[_vm._v("Mes BC")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Rechercher...","single-line":"","hide-details":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.computedHeaders,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"items":_vm.orders,"search":_vm.search,"footer-props":{
            itemsPerPageOptions: [10, 30, 50],
            itemsPerPageText: 'Élements par page',
          }},on:{"update:options":function($event){_vm.options=$event},"pagination":_vm.paginationUpdate},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(ref){
          var pageStart = ref.pageStart;
          var pageStop = ref.pageStop;
          var itemsLength = ref.itemsLength;
return [_vm._v(_vm._s(pageStart)+"-"+_vm._s(pageStop)+" de "+_vm._s(itemsLength))]}},{key:"body",fn:function(ref){
          var items = ref.items;
return [_c('tbody',{staticClass:"text-left cursor-pointer"},_vm._l((items),function(item){return _c('tr',{key:item._id,on:{"click":function($event){_vm.$route &&
                  _vm.$route.query &&
                  _vm.$route.query.status &&
                  ['to-order', 'to-order-contractor'].indexOf(
                    _vm.$route.query.status
                  ) !== -1
                    ? _vm.$router.push(("/orders/" + (item._id) + "/validations"))
                    : _vm.$router.push(("/orders/" + (item._id)))}}},[_c('td',{class:item.deletedAt ? 'text-decoration-line-through' : ''},[_vm._v(" E-"+_vm._s(item.orderId)+"-"+_vm._s(item.creatorTla)+" ")]),_c('td',{class:item.deletedAt ? 'text-decoration-line-through' : ''},[_vm._v(" "+_vm._s(item.supplier.name)+" ")]),_c('td',{class:item.deletedAt ? 'text-decoration-line-through' : ''},[_vm._v(" "+_vm._s(new Intl.NumberFormat("fr-CH", { style: "currency", currency: "CHF", }).format(item.computedTotal))+" ")]),_c('td',{class:item.deletedAt ? 'text-decoration-line-through' : ''},[_vm._v(" "+_vm._s(item.year)+" ")]),_c('td',{class:item.deletedAt ? 'text-decoration-line-through' : ''},[_vm._v(" "+_vm._s(_vm.format(_vm.parseISO(item.poDate), "dd.MM.yyyy"))+" ")]),_c('td',{class:item.deletedAt ? 'text-decoration-line-through' : ''},[_vm._v(" "+_vm._s(item.projectNumber)+" ")]),(_vm.$route.query.status === 'draft')?_c('td',{staticClass:"text-center"},[(!item.deletedAt && _vm.deleteConfirmation !== item._id)?_c('v-btn',{attrs:{"small":"","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.setDeleteConfirmation(item._id)}}},[_vm._v("Supprimer")]):_vm._e(),(!item.deletedAt && _vm.deleteConfirmation === item._id)?_c('v-btn',{attrs:{"small":"","color":"error","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(item._id)}}},[_vm._v("Confirmer")]):_vm._e(),(item.deletedAt)?_c('div',[_vm._v(" "+_vm._s(_vm.format(_vm.parseISO(item.deletedAt), "dd.MM.yyyy HH:mm"))+" ")]):_vm._e()],1):_c('td',[_vm._v(" "+_vm._s(item.lastValidation && item.lastValidation.fullname)+" - "+_vm._s(item.lastValidation && _vm.format( _vm.parseISO(item.lastValidation.createdAt), "dd.MM.yyyy" ))+" ")])])}),0)]}}])})],1)],1)],1),_c('v-snackbar',{attrs:{"color":_vm.snackbar.color,"top":"","right":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar.visible = false}}},'v-btn',attrs,false),[_vm._v(" Fermer ")])]}}]),model:{value:(_vm.snackbar.visible),callback:function ($$v) {_vm.$set(_vm.snackbar, "visible", $$v)},expression:"snackbar.visible"}},[_vm._v(" "+_vm._s(_vm.snackbar.text)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }