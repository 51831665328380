<template>
  <v-app>
    <v-app-bar app flat color="grey lighten-4">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <user-profile />
    </v-app-bar>
    <v-navigation-drawer app v-model="drawer" overlay-opacity="0">
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-avatar tile>
            <img
              src="https://storage.googleapis.com/visual-identity/logo/2020-slim.svg"
              alt="Logo HEIG-VD"
            />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>BONS DE COMMANDE</v-list-item-title>
            <v-list-item-subtitle>Interface de gestion</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-divider></v-divider>
      <v-list dense nav>
        <v-list-item link to="/orders/create" exact>
          <v-list-item-icon>
            <v-icon>mdi-note-plus-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Nouveau BC</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-subheader>MES BONS DE COMMANDE</v-subheader>
        <v-list-item link to="/orders?status=draft" exact>
          <v-list-item-icon>
            <v-icon>mdi-file-document-edit-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>En préparation </v-list-item-title>
          </v-list-item-content>
          <v-badge
            class="mr-5 mt-3"
            v-if="badges && badges.drafts > 0"
            :content="badges.drafts"
            color="error"
          ></v-badge>
        </v-list-item>
        <v-list-item
          link
          to="/validations?status=to-update&type=validation"
          exact
        >
          <v-list-item-icon>
            <v-icon>mdi-timeline-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>À modifier</v-list-item-title>
          </v-list-item-content>
          <v-badge
            class="mr-5 mt-3"
            v-if="badges && badges.pendingUpdates > 0"
            :content="badges.pendingUpdates"
            color="error"
          ></v-badge>
        </v-list-item>
        <v-list-item link to="/orders?status=processing" exact>
          <v-list-item-icon>
            <v-icon>mdi-timeline-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>En cours de validation</v-list-item-title>
          </v-list-item-content>
          <v-badge
            class="mr-5 mt-3"
            v-if="badges && badges.processing > 0"
            :content="badges.processing"
            color="primary"
          ></v-badge>
        </v-list-item>
        <v-list-item link to="/orders?status=to-order" exact>
          <v-list-item-icon>
            <v-icon>mdi-timeline-check-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>À commander</v-list-item-title>
          </v-list-item-content>
          <v-badge
            class="mr-5 mt-3"
            v-if="badges && badges.toOrder > 0"
            :content="badges.toOrder"
            color="error"
          ></v-badge>
        </v-list-item>

        <v-list-item link to="/orders?status=archive" exact>
          <v-list-item-icon>
            <v-icon>mdi-file-document-multiple-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Historique</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <div v-if="isBuyer">
          <v-subheader>AUTRES BONS DE COMMANDE</v-subheader>
          <v-list-item link to="/orders?status=to-order-contractor" exact>
            <v-list-item-icon>
              <v-icon>mdi-timeline-alert-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>À commander autre</v-list-item-title>
            </v-list-item-content>
            <v-badge
              class="mr-5 mt-3"
              v-if="badges && badges.toOrderContractor > 0"
              :content="badges.toOrderContractor"
              color="error"
            ></v-badge>
          </v-list-item>
          <v-list-item
            link
            to="/orders?status=to-order-contractor-archive"
            exact
          >
            <v-list-item-icon>
              <v-icon>mdi-file-document-multiple-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Historique</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
        <v-subheader>VALIDATIONS</v-subheader>
        <v-list-item
          link
          to="/validations?status=processing&type=validation"
          exact
        >
          <v-list-item-icon>
            <v-icon>mdi-timeline-question-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>À valider</v-list-item-title>
          </v-list-item-content>
          <v-badge
            class="mr-5 mt-3"
            v-if="badges && badges.pendingValidations > 0"
            :content="badges.pendingValidations"
            color="error"
          ></v-badge>
        </v-list-item>
        <v-list-item
          link
          to="/validations?status=archive&type=validation"
          exact
        >
          <v-list-item-icon>
            <v-icon>mdi-file-document-multiple-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Historique</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <div v-if="isValidator">
          <v-subheader>PRÉAVIS</v-subheader>
          <v-list-item
            link
            to="/validations?status=processing&type=notice"
            exact
          >
            <v-list-item-icon>
              <v-icon>mdi-timeline-question-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>À préaviser</v-list-item-title>
            </v-list-item-content>
            <v-badge
              class="mr-5 mt-3"
              v-if="badges && badges.pendingNotices > 0"
              :content="badges.pendingNotices"
              color="error"
            ></v-badge>
          </v-list-item>
          <v-list-item link to="/validations?status=archive&type=notice" exact>
            <v-list-item-icon>
              <v-icon>mdi-file-document-multiple-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Historique</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
        <v-subheader>CONFIGURATION</v-subheader>
        <v-list-item link to="/suppliers" exact>
          <v-list-item-icon>
            <v-icon>mdi-truck-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Mes fournisseurs</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <router-view
        :updateBadge="updateBadge"
        :setBadge="setBadge"
        :isAccountant="isAccountant"
      />
    </v-main>
  </v-app>
</template>

<script>
import UserProfile from "@/components/UserProfile";
import axios from "axios";
export default {
  data: () => ({ drawer: null, badges: undefined }),
  methods: {
    updateBadge(name, value) {
      this.badges[name] += value;
    },
    setBadge(name, value) {
      if (this.badges) {
        this.badges[name] = value;
      }
    },
  },
  components: { UserProfile },
  computed: {
    isBuyer() {
      return (
        this.$keycloak &&
        this.$keycloak.resourceAccess &&
        this.$keycloak.resourceAccess.po &&
        this.$keycloak.resourceAccess.po.ui &&
        this.$keycloak.resourceAccess.po.ui.roles.length &&
        this.$keycloak.resourceAccess.po.ui.roles.indexOf("buyer") > -1
      );
    },
    isValidator() {
      return (
        this.$keycloak &&
        this.$keycloak.resourceAccess &&
        this.$keycloak.resourceAccess.po &&
        this.$keycloak.resourceAccess.po.ui &&
        this.$keycloak.resourceAccess.po.ui.roles.length &&
        this.$keycloak.resourceAccess.po.ui.roles.indexOf("validator") > -1
      );
    },
    isAccountant() {
      return (
        this.$keycloak &&
        this.$keycloak.resourceAccess &&
        this.$keycloak.resourceAccess.po &&
        this.$keycloak.resourceAccess.po.ui &&
        this.$keycloak.resourceAccess.po.ui.roles.length &&
        this.$keycloak.resourceAccess.po.ui.roles.indexOf("comptabilite") > -1
      );
    },
  },
  async mounted() {
    try {
      const { data: badges } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/badges`,
      });
      this.badges = badges;
    } catch (error) {
      console.log(error);
    }

    window.addEventListener("unload", () => {
      localStorage.setItem(
        "snackbar",
        JSON.stringify({
          visible: false,
          color: "",
          text: "",
        })
      );
    });
    localStorage.setItem("supplier", JSON.stringify({}));
    localStorage.setItem("order", JSON.stringify({}));
    localStorage.setItem("po", JSON.stringify({}));
  },
  beforeDestroy() {
    window.removeEventListener("unload", () => {
      localStorage.setItem(
        "snackbar",
        JSON.stringify({
          visible: false,
          color: "",
          text: "",
        })
      );
      localStorage.setItem("supplier", JSON.stringify({}));
      localStorage.setItem("order", JSON.stringify({}));
      localStorage.setItem("po", JSON.stringify({}));
    });
    localStorage.setItem(
      "snackbar",
      JSON.stringify({
        visible: false,
        color: "",
        text: "",
      })
    );
  },
};
</script>

<style>
.cursor-pointer:hover {
  cursor: pointer;
}
</style>