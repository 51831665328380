<template>
  <v-container :fluid="$vuetify.breakpoint.lgAndDown">
    <v-btn block outlined class="my-5" to="/suppliers/create"
      >Créer un fournisseur</v-btn
    >
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            Mes fournisseurs
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Rechercher..."
              single-line
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="suppliers"
            :search="search"
            class="elevation-1"
            :footer-props="{
              itemsPerPageOptions: [10, 30, 50],
              itemsPerPageText: 'Élements par page',
            }"
          >
            <!-- eslint-disable-next-line vue/valid-v-slot justification: possible according to vuetify documentation -->
            <template
              v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }"
              >{{ pageStart }}-{{ pageStop }} de {{ itemsLength }}</template
            >
            <template v-slot:body="{ items }">
              <tbody class="text-left cursor-pointer">
                <tr
                  v-for="item in items"
                  :key="item._id"
                  @click="$router.push(`/suppliers/${item._id}`)"
                >
                  <td>{{ item.name }}</td>
                  <td>{{ item.addressOne }}</td>
                  <td>{{ item.addressTwo }}</td>
                  <td>{{ item.zip }}</td>
                  <td>{{ item.city }}</td>
                  <td>{{ item.country }}</td>
                  <td>{{ item.email }}</td>
                  <td class="text-center">
                    <v-btn
                      v-if="!item.deletedAt && deleteConfirmation !== item._id"
                      small
                      text
                      @click.stop="setDeleteConfirmation(item._id)"
                      >Supprimer</v-btn
                    >
                    <v-btn
                      v-if="deleteConfirmation === item._id"
                      small
                      color="error"
                      text
                      @click.stop="deleteItem(item._id)"
                      >Confirmer</v-btn
                    >
                  </td>
                </tr>
              </tbody></template
            >
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn block outlined class="my-5" @click="exportSuppliers"
          >Exporter mes fournisseurs</v-btn
        ></v-col
      >
      <v-col>
        <v-btn block outlined class="my-5" @click="openImportDialog"
          >Importer des fournisseurs</v-btn
        ></v-col
      >
    </v-row>
    <v-dialog
      v-model="importDialog"
      max-width="600px"
      @click:outside="closeDialog"
    >
      <v-card>
        <v-card-title>Importer des fournisseurs</v-card-title>
        <v-card-text>
          <div>Importez des fournisseurs en dépôsant un fichier d'export.</div>
          <v-file-input
            outlined
            dense
            label="Export CSV"
            class="mt-5"
            @change="parseCsv"
            ref="suppliers"
            accept=".csv"
            @click:clear="removeFile"
            v-model="file"
          ></v-file-input>
          <div v-if="parsedSuppliers.length">
            {{ parsedSuppliers.length }} fournisseurs à importer
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDialog">Annuler</v-btn>
          <v-btn
            text
            @click="importSuppliers"
            :disabled="!parsedSuppliers.length"
            >Importer</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import { format, parseISO } from "date-fns";
import Papa from "papaparse";

export default {
  data: () => ({
    format,
    parseISO,
    suppliers: undefined,
    parsedSuppliers: [],
    search: "",
    file: undefined,
    deleteConfirmation: "",
    importDialog: false,
    headers: [
      {
        text: "Nom",
        align: "start",
        value: "name",
      },
      { text: "Adresse 1", value: "addressOne" },
      { text: "Adresse 2", value: "addressTwo" },
      { text: "NPA", value: "zip" },
      { text: "Ville", value: "city" },
      { text: "Pays", value: "country" },
      { text: "E-mail", value: "email" },
      { text: "Suppression" },
    ],
  }),
  async mounted() {
    try {
      const { data: suppliers } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/suppliers`,
      });
      this.suppliers = suppliers;
    } catch (error) {
      this.snackbar = {
        visible: true,
        color: "error",
        text: "Erreur de chargement",
      };
    }
  },
  methods: {
    closeDialog() {
      this.removeFile();
      this.importDialog = false;
    },
    removeFile() {
      this.file = undefined;
      this.parsedSuppliers = [];
    },
    async parseCsv(file) {
      if (file) {
        const supplierPromise = new Promise((resolve, reject) => {
          Papa.parse(file, {
            header: true,
            skipEmptyLines: "greedy",
            complete(results) {
              resolve(results.data);
            },
            error(err) {
              reject(err);
            },
          });
        });
        const parsedSuppliers = await supplierPromise;
        this.parsedSuppliers = parsedSuppliers;
      }
    },
    async importSuppliers() {
      try {
        const { data } = await axios({
          method: "post",
          url: `${process.env.VUE_APP_API_URI}/suppliers?multiple=true`,
          data: this.parsedSuppliers,
        });
        this.suppliers = [...this.suppliers, ...data];
      } catch (error) {
        this.snackbar = {
          visible: true,
          color: "error",
          text: "Erreur d'importation",
        };
      } finally {
        this.closeDialog();
      }
    },
    openImportDialog() {
      this.importDialog = true;
    },
    async exportSuppliers() {
      try {
        const { data } = await axios({
          method: "get",
          url: `${process.env.VUE_APP_API_URI}/suppliers?format=csv`,
        });
        const blob = new Blob([data], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("href", url);
        a.setAttribute(
          "download",
          `${format(
            new Date(),
            "yyyy-MM-dd"
          )}-${this.$keycloak.tokenParsed.given_name
            .normalize("NFKD")
            .replace(/[^\w]/g, "")
            .toLowerCase()}-${this.$keycloak.tokenParsed.family_name
            .normalize("NFKD")
            .replace(/[^\w]/g, "")
            .toLowerCase()}-fournisseurs.csv`
        );
        a.click();
      } catch (error) {
        this.snackbar = {
          visible: true,
          color: "error",
          text: "Erreur d'exportation",
        };
      }
    },
    setDeleteConfirmation(_id) {
      this.deleteConfirmation = _id;
    },
    async deleteItem() {
      try {
        await axios({
          method: "delete",
          url: `${process.env.VUE_APP_API_URI}/suppliers/${this.deleteConfirmation}`,
        });

        this.suppliers = this.suppliers.filter(
          (s) => s._id !== this.deleteConfirmation
        );
        this.snackbar = {
          visible: true,
          color: "success",
          text: "Élément supprimé",
        };
      } catch (error) {
        this.snackbar = {
          visible: true,
          color: "error",
          text: "Erreur de suppression",
        };
      } finally {
        this.deleteConfirmation = "";
      }
    },
  },
};
</script>