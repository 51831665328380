<template>
  <v-container :fluid="$vuetify.breakpoint.lgAndDown">
    <v-row>
      <v-col
        ><supplier-form
          :supplier="supplier"
          :submit="updateSupplier"
          :loading="loading"
          :steps="steps"
      /></v-col>
    </v-row>
    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" top right>
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.visible = false">
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import SupplierForm from "@/components/SupplierForm";
import axios from "axios";

export default {
  components: { SupplierForm },
  data: () => ({
    loading: false,
    steps: -1,
    supplier: undefined,
    snackbar: {
      visible: false,
      color: "",
      text: "",
    },
  }),
  async mounted() {
    const existingSupplier = JSON.parse(localStorage.getItem("supplier"));
    const snackbar = JSON.parse(localStorage.getItem("snackbar"));
    this.snackbar = snackbar;
    if (Object.keys(existingSupplier).length) {
      this.supplier = existingSupplier;
      this.steps = -2;
    } else {
      const { data: supplier } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/suppliers/${this.$route.params._id}`,
      });
      this.supplier = supplier;
      localStorage.setItem("supplier", JSON.stringify({}));
    }
    window.addEventListener("unload", () => {
      localStorage.setItem(
        "snackbar",
        JSON.stringify({
          visible: false,
          color: "",
          text: "",
        })
      );
      localStorage.setItem("supplier", JSON.stringify({}));
    });
  },
  beforeDestroy() {
    window.removeEventListener("unload", () => {
      localStorage.setItem(
        "snackbar",
        JSON.stringify({
          visible: false,
          color: "",
          text: "",
        })
      );
      localStorage.setItem("supplier", JSON.stringify({}));
    });
    localStorage.setItem(
      "snackbar",
      JSON.stringify({
        visible: false,
        color: "",
        text: "",
      })
    );
    localStorage.setItem("supplier", JSON.stringify({}));
  },
  methods: {
    async updateSupplier() {
      this.loading = true;
      try {
        const { data: supplier } = await axios({
          method: "patch",
          url: `${process.env.VUE_APP_API_URI}/suppliers/${this.supplier._id}`,
          data: {
            ...this.supplier,
          },
        });
        this.supplier = supplier;
        this.snackbar = {
          visible: true,
          color: "success",
          text: "Fournisseur mis à jour",
        };
      } catch (error) {
        this.snackbar = {
          visible: true,
          color: "error",
          text: "Erreur mise à jour du fournisseur",
        };
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
