var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-text-field',{directives:[{name:"currency",rawName:"v-currency",value:({
    currency: _vm.currency,
    valueRange: { min: 0 },
    allowNegative: false,
    locale: 'fr-CH',
    distractionFree: true
  }),expression:"{\n    currency: currency,\n    valueRange: { min: 0 },\n    allowNegative: false,\n    locale: 'fr-CH',\n    distractionFree: true\n  }"}],ref:"input",attrs:{"value":_vm.formattedValue,"outlined":"","dense":"","hint":_vm.currency !== 'CHF'
      ? ("Estimation : CHF " + ((_vm.value / this.rates[_vm.currency]).toFixed(2)))
      : '',"persistent-hint":_vm.currency !== 'CHF' && !!_vm.value,"rules":_vm.rules,"label":_vm.label},on:{"change":_vm.onChange,"input":_vm.onInput}})}
var staticRenderFns = []

export { render, staticRenderFns }