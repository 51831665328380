var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":_vm.$vuetify.breakpoint.lgAndDown}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[(_vm.current)?_c('v-form',{ref:"form",attrs:{"lazy-validation":"","disabled":!!_vm.current.deletedAt || _vm.current.status !== 'draft'},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('purchase-order-form',{attrs:{"current":_vm.current,"generating":_vm.generating,"computedValue":_vm.computedValue,"computedVat":_vm.computedVat,"computedDelivery":_vm.computedDelivery,"computedTotal":_vm.computedTotal,"requiredFields":_vm.requiredFields,"rates":_vm.rates}})],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('div',{staticStyle:{"position":"sticky","top":"76px"}},[_c('v-btn',{attrs:{"outlined":"","block":"","loading":_vm.generating,"disabled":!this.requiredFields ||
            !this.valid ||
            !!_vm.current.deletedAt ||
            _vm.current.status !== 'draft'},on:{"click":function($event){return _vm.createOrder(Object.assign({}, {rates: _vm.rates},
              _vm.current,
              {computedTotal: _vm.computedTotal}))}}},[_vm._v("Générer")]),_c('validation-list',{attrs:{"po":_vm.current,"computedValue":_vm.computedValue}})],1)])],1),_c('v-snackbar',{attrs:{"color":_vm.snackbar.color,"top":"","right":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
            var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar.visible = false}}},'v-btn',attrs,false),[_vm._v(" Fermer ")])]}}]),model:{value:(_vm.snackbar.visible),callback:function ($$v) {_vm.$set(_vm.snackbar, "visible", $$v)},expression:"snackbar.visible"}},[_vm._v(" "+_vm._s(_vm.snackbar.text)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }